
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  MailRootState,
  getIsConversationLoading,
  getHasMoreConversations,
  getSelectedConversationIds,
  getCheckedConversationIds,
  getSelectedMessageIds,
  getCheckedMessageIds,
  getMailDetailId,
  getMessageDetailId,
  getIsMailsLoading,
  getMailTabs,
  getSelectedTab,
  getLastConversationData,
  getLastConversationDataById
} from "../store";
import { MailService } from "../shared/services/mail-service";
import { SearchRequest, Conversation, Message, SaveSendMessage } from "../shared/models";
import { take, filter, map, skip, distinctUntilChanged, catchError } from "rxjs/operators";
import { Observable, Subject, pipe, BehaviorSubject, forkJoin } from "rxjs";
import {
  SetQueryAction,
  IsConversationLoadingAction,
  LoadConversationSuccessAction,
  LoadConversationFailedAction,
  SelectConversationAction,
  SelectMessageAction,
  ResetSelectedConversationsAction,
  UnSelectConversationAction,
  UnSelectMessageAction,
  CheckConversationAction,
  UnCheckConversationAction,
  CheckMessageAction,
  UnCheckMessageAction,
  ResetCheckedConversationsAction,
  MultipleCheckConversationsAction,
  UpdateManyMessagesSuccess,
  RemoveManyMessages,
  SetMessageDetailId,
  LoadMailSuccessAction,
  LoadMailFailedAction,
  ResetSelectedMessagesAction,
  ResetCheckedMessagesAction,
  IsLoadingAction,
  ResetMessagesAction,
  CheckAllMessagesAction,
  RemoveMessagesFromFolder,
  SetHasMoreAction
} from "../store/actions";
import {
  getConversations,
  getSelectedConversations,
  getCheckedConversations,
  getSelectedMessages,
  getCheckedMessages,
  getMessagesByIds,
  getConversationsByIds, getConversationsByQuery, getCurrentConversations, getConversationsByTag,
  getConversationById,
  getMessageById,
  getCurrentMessages,
  getSystemFolders
} from "../store/selectors";
import {
  UpdateManyConversationsSuccess,
  ResetConversationsByFolderAction,
  ResetConversationsAction,
  RemoveManyConversations,
  SetMailDetailId,
  SetCurrentQuery,
  UpdateConversationSuccess,
  RemoveAllConversationsFromFolder,
  AddTab,
  RemoveTab,
  RemoveMultipleTabs,
  UpdateLastConversationData,
  AddFirstTab
} from "../store/actions/conversation.action";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { getContactProfiles, getExpandConversation, getOnlineStatus, getCurrentFolder, getViewBy } from "src/app/reducers";
import { MailUtils } from "../utils/mail-utils";
import { Contact } from "../shared/models/contact";
import { getUserProfile } from "../../reducers/index";
import { MailBroadcaster } from "../../common/providers/mail-broadcaster.service";
import { FilesStorageService } from "../../services/files-storage.service";
import { CommonUtils } from "../../common/utils/common-util";
import { SetViewBy, SetReadingPanel, RestoreLastPhotoUpdate } from "src/app/actions/app";
import { ToastService } from "../../common/providers/toast.service";
import * as _ from "lodash";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import {
  ConfirmationData,
  ConfirmationDialogComponent
} from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DatabaseService } from "src/app/services/db/database.service";
import { SearchConvRequest } from "../shared/models/search-conv-request.model";
import { SharedUtils } from "../utils/shared.utils";
import { MailTag } from "../models/mail-tag.model";
import { SearchItem, SearchResponse } from "src/app/common/models/search-item";
import { ConfigService } from "src/app/config.service";
import { MailFolder } from "../models/mail-folder.model";
import { ElectronService } from "src/app/services/electron.service";
import { M } from "@angular/cdk/keycodes";
import { DeleteCalendarAppointmentsSuccessAction } from "src/app/actions/calendar.actions";
import { PreferenceRepository } from "src/app/preference/repositories/preference.repository";

@Injectable()
export class ConversationRepository {

  conversations: Conversation[] = [];
  contactProfiles: Contact[] = [];
  currentUser: any;
  messagesUpdate$ = new Subject<Message[]>();
  multiTabPendingLock$ = new BehaviorSubject<number>(0);
  quickFilterSearchString$ = new BehaviorSubject<string>("");
  prefs: any = {};

  isOnline = false;
  isSyncRequestDone = false;
  resyncInvalidMessagesRunning = false;
  isProcessingPendingOps = false;

  currentQuery: string;
  currentFolder: string;
  lastTimeResume: any;
  inTrashIds = [];
  bc: any;
  constructor(
    private mailService: MailService,
    private store: Store<MailRootState>,
    private mailBroadcaster: MailBroadcaster,
    private filesStorageService: FilesStorageService,
    private toastService: ToastService,
    private router: Router,
    private databaseService: DatabaseService,
    private electronService: ElectronService,
    private matDialog: MatDialog,
    private configService: ConfigService,
    private preferenceRepo: PreferenceRepository,
  ) {
    console.log("[ConversationRepository][constructor]");
    this.bc = new BroadcastChannel("vncmail_channel");
    this.bc.onmessage = (ev) => {
      if (!!ev.data && ev.data.startsWith("multitab_lock")) {
        if (ev.data === "multitab_lock_0") {
          console.log("broadcastChannel: setMultiTabPendingLock 0");
          this.setMultiTabPendingLock(0);
        } else {
          console.log("broadcastChannel: setMultiTabPendingLock ", Date.now());
          this.setMultiTabPendingLock(Date.now());
        }
      }
    }



    this.store.select(getContactProfiles).subscribe(res => {
      if (res) {
        this.contactProfiles = res;
      }
    });
    this.store.select(getViewBy).pipe(skip(1)).subscribe(value => {
      localStorage.setItem("currentView", value);
    });
    this.store.select(getUserProfile).pipe(filter(v => !!v)).subscribe(res => {
      if (!!res) {
        this.currentUser = res;
      } else {
        const contactUser = localStorage.getItem("profileUser");
        if (contactUser && contactUser !== undefined && contactUser !== "undefined") {
          this.currentUser = MailUtils.parseUserProfile(contactUser);
        }
      }
    });

    this.mailBroadcaster.on("MARK_AS_READ").subscribe((res: any) => {
      if (res.isMessage) {
        this.markReadUnreadMessages([res.id], true);
      } else {
        this.markReadUnreadConversations([res.id], true);
      }
    });

    this.store.select(getOnlineStatus).subscribe(res => {
      this.isOnline = res;
      console.log("[ConversationRepository][getOnlineStatus]", res);
      if (this.electronService.isElectron) {
        this.electronService.remoteLog("[ConversationRepository][getOnlineStatus]", res);
        this.electronService.remoteLog("[ConversationRepository][systemIdleState]", this.electronService.getSystemIdleState());
      }
    });

    this.store.select(getCurrentFolder).subscribe(folder => {
      this.currentFolder = folder;
      this.currentQuery = MailUtils.getQueryByFolderId(folder);
      // console.log("[ConversationRepository][getCurrentFolder]", folder, this.currentQuery);
    });

    if (!!localStorage.getItem("lastPhotoUpdate")) {
      try {
        this.store.dispatch(new RestoreLastPhotoUpdate(JSON.parse(localStorage.getItem("lastPhotoUpdate"))));
      } catch (ex) {

      }
    }

    if (!!localStorage.getItem("currentView")) {
      this.store.dispatch(new SetViewBy(localStorage.getItem("currentView")));
    }

    if (!!localStorage.getItem("readingPane")) {
      this.store.dispatch(new SetReadingPanel(localStorage.getItem("readingPane")));
    }

    this.multiTabPendingLock$.pipe(distinctUntilChanged()).subscribe(l => {
      this.bc.postMessage("multitab_lock_" + l);
    });
  }

  setSyncRequestDone() {
    console.log("[ConversationRepository] setSyncRequest done");
    this.isSyncRequestDone = true;
  }

  setSyncRequestInProgess() {
    console.log("[ConversationRepository] setSyncRequest in progress");
    this.isSyncRequestDone = false;
  }

  getSyncRequestDone() {
    return this.isSyncRequestDone;
  }
  /// Convs store

  getConversations(): Observable<Conversation[]> {
    return this.store.select(getConversations);
  }

  getCurrentConversations(): Observable<Conversation[]> {
    return this.store.select(getCurrentConversations);
  }

  getConversationsByIds(ids: string[]): Observable<Conversation[]> {
    return this.store.select(state => getConversationsByIds(state, ids));
  }

  getConversationById(id: string): Observable<Conversation> {
    return this.store.select(state => getConversationById(state, id));
  }

  getConversationsByQuery(query: string): Observable<Conversation[]> {
    return this.store.select(state => getConversationsByQuery(state, query));
  }

  getConversationsByTag(tagName: string): Observable<Conversation[]> {
    return this.store.select(state => getConversationsByTag(state, tagName));
  }

  getSelectedConversations(): Observable<Conversation[]> {
    return this.store.select(getSelectedConversations);
  }

  getCheckedConversations(): Observable<Conversation[]> {
    return this.store.select(getCheckedConversations);
  }


  /// Messages store

  getCurrentMessages(): Observable<Message[]> {
    return this.store.select(getCurrentMessages);
  }

  getMessagesByIds(ids: string[]): Observable<Message[]> {
    return this.store.select(state => getMessagesByIds(state, ids));
  }

  getMessageById(id: string): Observable<Message> {
    return this.store.select(state => getMessageById(state, id));
  }

  getSelectedMessages(): Observable<Message[]> {
    return this.store.select(getSelectedMessages);
  }

  getCheckedMessages(): Observable<Message[]> {
    return this.store.select(getCheckedMessages);
  }


  ////

  getConversationsList(query: SearchRequest, noLoading?: boolean, searchKey?: any): Observable<Conversation[]> {
    console.log("[ConversationRepository][getConversationsList]", query, this.isOnline, this.databaseService.dbReady);

    const response = new Subject<Conversation[]>();

    this.setQuery(query);
    this.setCurrentQuery(query.originalQuery);
    if (!noLoading) {
      this.setConversationIsLoading(true);
    }
    // const t1 = new Date().getTime();

    // query.originalQuery:
    //
    // in:inbox
    // in:drafts
    // in:sent
    // is:flagged or ( in:trash and is:flagged )
    // in:junk
    // in:trash

    if (this.isOnline) {
      const searchKeyWord = !!searchKey ? searchKey.trim() : "";
      if (searchKeyWord !== "") {
        let keywords = searchKeyWord.split(" ");
        let words = (keywords.length > 1) ? "(" + keywords.join("* OR ") + ")" : searchKeyWord + "*";
        let q = words + " AND " + query.query;
        if (searchKeyWord.indexOf('"') > -1) {
          q = searchKeyWord + " " + query.query;
        }

        let quickQuery = query;
        quickQuery.query = q;

        this.mailService.getConversationsList(quickQuery).subscribe(conversations => {
          console.log(`[ConversationRepository][getConversationsList](Network) conversations`, conversations);
          let filteredConversations = conversations;
          if (query.originalQuery !== "in:trash") {
            filteredConversations = conversations.filter(v => !this.inTrashIds.includes(v.id));
          }
          this.setConversationIsLoading(false);
          this.setConversationLoadedSuccess(filteredConversations);

          // save to DB
          // TODO: maybe do not wait for DB write and return resp in parallel
          this.addConversationsToDB(filteredConversations).subscribe(res => {
            response.next(filteredConversations);
            response.complete();
          }, error => {
            console.error(`[ConversationRepository][getConversationsList][addConversations]`, error);

            response.next(filteredConversations);
            response.complete();
          });
        }, err => {
          console.error(`[ConversationRepository][getConversationsList]`, err);

          this.setConversationIsLoading(false);
          this.setConversationLoadedFail();

          response.next([]);
          response.complete();
        });

      } else {
        // load from DB first
        if (this.databaseService.dbReady && query?.sortBy && query?.sortBy !== "flagAsc" && query?.sortBy !== "flagDesc"
          && query?.sortBy !== "attachAsc" && query?.sortBy !== "readDesc" && query?.sortBy !== "readAsc"
          && query?.sortBy !== "attachDesc") {
          this.getConversationsListFromDB(query, noLoading).subscribe(conversationsDB => {
            console.log(`[ConversationRepository][getConversationsList](DB) conversations`, conversationsDB);
            let filteredConversations = conversationsDB;
            if (query.originalQuery !== "in:trash") {
              filteredConversations = conversationsDB.filter(v => !this.inTrashIds.includes(v.id));
            }
            if (filteredConversations.length > 0) {
              this.setConversationIsLoading(false);
              this.setConversationLoadedSuccess(filteredConversations);
              response.next(filteredConversations);
            }

            this.mailService.getConversationsList(query).subscribe(conversations => {
              console.log(`[ConversationRepository][getConversationsList](Network) conversations`, conversations);
              conversations.forEach(conv => {
                const convDB = conversationsDB.find(v => v.id === conv.id && conv.m && conv.m.length > 0);
                if (convDB && convDB.m) {
                  console.log(`[ConversationRepository][getConversationsListConvDB](Network) found conversation with messages from db`, convDB.id, convDB);
                  conv.m = conv.m.map(m => {
                    const mDB = convDB.m.find(mDB => mDB.id === m.id);
                    if (mDB) {
                      m = { ...mDB, ...m };
                    }
                    return m;
                  });
                }
              });
              filteredConversations = conversations;
              if (query.originalQuery !== "in:trash") {
                filteredConversations = conversations.filter(v => !this.inTrashIds.includes(v.id));
              }
              this.setConversationIsLoading(false);
              this.setConversationLoadedSuccess(filteredConversations);

              // save to DB
              // TODO: maybe do not wait for DB write and return resp in parallel
              this.addConversationsToDB(filteredConversations).subscribe(res => {
                response.next(filteredConversations);
                response.complete();
              }, error => {
                console.error(`[ConversationRepository][getConversationsList][addConversations]`, error);

                response.next(filteredConversations);
                response.complete();
              });
            }, err => {
              console.error(`[ConversationRepository][getConversationsList]`, err);

              this.setConversationIsLoading(false);
              this.setConversationLoadedFail();

              response.next([]);
              response.complete();
            });

          });
        } else {
          console.error(`[ConversationRepository][getConversationsList] got stuck`, this.databaseService.dbReady);
          this.mailService.getConversationsList(query).subscribe(conversations => {
            console.log(`[ConversationRepository][getConversationsList](Network) conversations`, conversations);

            this.setConversationIsLoading(false);
            this.setConversationLoadedSuccess(conversations);

            // save to DB
            // TODO: maybe do not wait for DB write and return resp in parallel
            if (this.databaseService.dbReady) {
              this.addConversationsToDB(conversations).subscribe(res => {
                response.next(conversations);
                response.complete();
              }, error => {
                console.error(`[ConversationRepository][getConversationsList][addConversations]`, error);

                response.next(conversations);
                response.complete();
              });
            } else {
              response.next(conversations);
              response.complete();
            }

          }, err => {
            console.error(`[ConversationRepository][getConversationsList]`, err);

            this.setConversationIsLoading(false);
            this.setConversationLoadedFail();

            response.next([]);
            response.complete();
          });
        }

      }
    } else {
      this.getConversationsListFromDB(query, noLoading).subscribe(conversations => {
        this.setConversationIsLoading(false);
        this.setConversationLoadedSuccess(conversations);

        console.log(`[ConversationRepository][getConversationsList](DB) conversations`, conversations.length);

        response.next([]);
        response.complete();
      });
    }

    return response.asObservable();
  }

  private getConversationsListFromDB(query: SearchRequest, noLoading?: boolean): Observable<Conversation[]> {
    console.log(`[ConversationRepository][getConversationsListFromDB]`, query);

    const response = new Subject<Conversation[]>();

    let observable: Observable<Conversation[]>;

    // starred
    if (query.originalQuery === "is:flagged or ( in:trash and is:flagged )") {
      observable = this.databaseService.getConversationsStarred();

    // sent
    } else if (query.originalQuery === "in:sent") {
      // observable = this.databaseService.getConversationsSent();
      observable = this.databaseService.getConversationsByFolder(query.originalQuery);

    // other folders
    } else {
      observable = this.databaseService.getConversationsByFolder(query.originalQuery);
    }

    // get from DB
    observable.subscribe(conversations => {
      // it's required for 'getCurrentConversations' selector to work properly
      conversations.forEach(c => {
        c.query = query.originalQuery;
      });

      response.next(conversations);
    }, error => {
      console.error(`[ConversationRepository][getConversationsListFromDB]`, error);

      response.next([]);
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesList(query: SearchRequest, noLoading?: boolean, includingId?: string, quickFilterActive?: boolean, searchKey?: any): Observable<Message[]> {
    console.log("[ConversationRepository][getMessagesList2]", query, noLoading, this.isOnline, includingId);

    const response = new Subject<Message[]>();

    this.setQuery(query);
    this.setCurrentQuery(query.originalQuery);
    if (!noLoading) {
      this.setConversationIsLoading(true);
    }

    // query.originalQuery:
    //
    // in:inbox
    // in:drafts
    // in:sent
    // is:flagged or ( in:trash and is:flagged )
    // in:junk
    // in:trash
    const searchKeyWord = !!searchKey ? searchKey.trim() : "";
    if (searchKeyWord !== "") {
      let keywords = searchKeyWord.split(" ");
      let words = (keywords.length > 1) ? "(" + keywords.join("* OR ") + "*)" : searchKeyWord + "*";
      let q = words + " AND " + query.query;
      if (searchKeyWord.indexOf('"') > -1) {
        q = searchKeyWord + " " + query.query;
      }

      let quickQuery = query;
      quickQuery.query = q;

      this.mailService.getMessagesList(quickQuery).pipe(take(1)).subscribe(messages3 => {
        console.log("[ConversationRepository][getMessagesList](Network) messagesToAddWithQuery1 ", messages3.length, messages3);
        let filteredMessages3 = messages3;
        if (quickQuery.originalQuery !== "in:trash") {
          filteredMessages3 = filteredMessages3.filter(v => !this.inTrashIds.includes(v.id));
        }
        this.setConversationIsLoading(false);
        this.addMessagesToStore(filteredMessages3);

        // save to DB
        // TODO: maybe do not wait for DB write and return resp in parallel
        this.addMessagesToDB(filteredMessages3, query).subscribe(() => {
          response.next(filteredMessages3);
          response.complete();
        }, error => {
          console.error(`[ConversationRepository][getMessagesList][addMessages]`, error);
          response.next(filteredMessages3);
          response.complete();
        });
      }, err => {
        console.error("[ConversationRepository][getMessagesList]", err);

        this.setConversationIsLoading(false);
        this.setMessageLoadedFail();

        response.next([]);
        response.complete();
      });


    } else {
      if ((CommonUtils.isOfflineModeSupported() || CommonUtils.isOnAndroid()) && (query?.sortBy && query?.sortBy !== "flagAsc" && query?.sortBy !== "flagDesc"
        && query?.sortBy !== "attachAsc" && query?.sortBy !== "readDesc" && query?.sortBy !== "readAsc"
        && query?.sortBy !== "attachDesc" && query.originalQuery !== "in:trash")) {

        this.getMessagesListFromDB(query, noLoading, includingId, quickFilterActive).subscribe(async messages => {
          const firstDBmessagesLenght = messages.length;

          let filteredMessages = messages;
          if (query.originalQuery !== "in:trash") {
            filteredMessages = filteredMessages.filter(v => !this.inTrashIds.includes(v.id));
          }

          let includingIdFound = true;
          let filteredIds = [];
          if (!!includingId) {
            filteredIds = filteredMessages.map(m => m.id);
          }
          if (query.originalQuery === "in:sent") {
            let refetchIds = filteredMessages.filter(m => m.e?.length < 2).map(m => m.id);
            const test1 = await this.updateMessagesFromServerById(refetchIds);
            const fetchedIds = Object.keys(test1);
            if (fetchedIds.length > 0) {
              for (let i = 0; i < filteredMessages.length; i++) {
                const fid = filteredMessages[i].id;
                if (filteredMessages[i].e?.length === 1) {
                  filteredMessages[i] = test1[fid];
                }
              }
            }
          }
          console.log("filteredMessages3: ", filteredMessages);
          this.addMessagesToStore(filteredMessages);
          response.next(filteredMessages);
          console.log(`[ConversationRepository][getMessagesList](DB1) messages`, messages.length, filteredMessages.length);

          if ((firstDBmessagesLenght === query.limit) || (!!includingId && filteredIds.includes(includingId))) {
            this.setConversationIsLoading(false);

            this.store.dispatch(new SetHasMoreAction(true));

            if (filteredMessages.length < query.limit) {
              const additionalQuery = { ...query };
              additionalQuery.offset = query.offset + query.limit;
                this.getMessagesListFromDB(additionalQuery, noLoading).subscribe(messages2 => {
                  let filteredMessages2 = messages2;
                  if (query.originalQuery !== "in:trash") {
                    filteredMessages2 = filteredMessages2.filter(v => !this.inTrashIds.includes(v.id));
                  }
                  if (filteredMessages2.length > 0) {
                    this.addMessagesToStore(filteredMessages2);
                    response.next(filteredMessages2);
                    this.checkForInvalidMessages();
                    response.complete();
                  }
                });
            } else {
              this.checkForInvalidMessages();
              response.complete();
            }
          } else {

            this.mailService.getMessagesList(query).pipe(take(1)).subscribe(async messages3 => {
              console.log("[ConversationRepository][getMessagesList](Network) messagesToAddWithQuery2 messages", query, messages3.length, messages3);
              let filteredMessages3 = messages3;
              if (query.originalQuery !== "in:trash") {
                filteredMessages3 = filteredMessages3.filter(v => !this.inTrashIds.includes(v.id));
              }
              if (query.originalQuery === "in:sent") {
                let refetchIds2 = filteredMessages3.filter(m => m.e?.length < 2).map(m => m.id);
                const test2 = await this.updateMessagesFromServerById(refetchIds2);
                const fetchedIds = Object.keys(test2);
                if (fetchedIds.length > 0) {
                  for (let i = 0; i < filteredMessages3.length; i++) {
                    const fid = filteredMessages3[i].id;
                    if (filteredMessages3[i].e?.length === 1) {
                      filteredMessages3[i] = test2[fid];
                    }
                  }
                }
              }
              console.log("filteredMessages3: ", filteredMessages3);
              this.setConversationIsLoading(false);
              this.addMessagesToStore(filteredMessages3);
              if (!!this.configService.worker) {
                this.configService.worker.postMessage({ type: "createOrUpdateMessages", id: new Date().getTime(), args: filteredMessages3 });
              } else {
                // save to DB
                // TODO: maybe do not wait for DB write and return resp in parallel
                this.addMessagesToDB(filteredMessages3, query).subscribe(() => {
                  response.next(filteredMessages3);
                  response.complete();
                }, error => {
                  console.error(`[ConversationRepository][getMessagesList][addMessages]`, error);
                  response.next(filteredMessages3);
                  response.complete();
                });
              }
            }, err => {
              console.error("[ConversationRepository][getMessagesList]", err);

              this.setConversationIsLoading(false);
              this.setMessageLoadedFail();

              response.next([]);
              response.complete();
            });

          }
        });

      } else {

        this.mailService.getMessagesList(query).pipe(take(1)).subscribe(async messages3 => {
          console.log("[ConversationRepository][getMessagesList](Network) messages", messages3.length, messages3);
          let filteredMessages3 = messages3;
          if (query.originalQuery !== "in:trash") {
            filteredMessages3 = filteredMessages3.filter(v => !this.inTrashIds.includes(v.id));
          }
          if (query.originalQuery === "in:sent") {
            let refetchIds3 = filteredMessages3.filter(m => m.e?.length < 2).map(m => m.id);
            const test3 = await this.updateMessagesFromServerById(refetchIds3);
            const fetchedIds = Object.keys(test3);
            if (fetchedIds.length > 0) {
              for (let i = 0; i < filteredMessages3.length; i++) {
                const fid = filteredMessages3[i].id;
                if (filteredMessages3[i].e?.length === 1) {
                  filteredMessages3[i] = test3[fid];
                }
              }
            }
          }

          this.setConversationIsLoading(false);
          this.addMessagesToStore(filteredMessages3);
          response.next(filteredMessages3);
          response.complete();
        }, err => {
          console.error("[ConversationRepository][getMessagesList]", err);

          this.setConversationIsLoading(false);
          this.setMessageLoadedFail();

          response.next([]);
          response.complete();
        });
      }
    }

    return response.asObservable();
  }

  getMessagesListFromDB(query: SearchRequest, noLoading?: boolean, includingId?: string, quickFilterActive?: boolean): Observable<Message[]> {
    console.log(`[ConversationRepository][getMessagesListFromDB] getMessagesByFolder`, query, quickFilterActive);

    const response = new Subject<Message[]>();

      let observable: Observable<Message[]>;

    // starred
    if (query.originalQuery === "is:flagged or ( in:trash and is:flagged )") {
      observable = this.databaseService.getMessagesStarred();

    // from folders
    } else {
      console.log(`[ConversationRepository][getMessagesListFromDB] getMessagesByFolder2`, query, !!quickFilterActive);
      observable = this.databaseService.getMessagesByFolder(query.originalQuery, query, includingId, !!quickFilterActive);
    }

    // get from DB
    observable.subscribe(messages => {
      // it's required for 'getCurrentMessages' selector to work properly
      messages.forEach(m => {
        m.query = query.originalQuery;
      });
      console.log("[ConversationRepository][getMessagesListFromDB] will return: ", messages);
      response.next(messages);
    }, error => {
      console.error(`[ConversationRepository][getMessagesListFromDB]`, error);

      response.next([]);
    });

    return response.asObservable().pipe(take(1));
  }

  setQuery(query: SearchRequest): void {
    this.store.dispatch(new SetQueryAction({ query }));
  }

  setCurrentQuery(query: string): void {
    console.log("convRepo setCurrentQuery setQuery: ", query);
    if (!!query && ((query === "in:drafts") || (query === "in:trash"))) {
      this.maybeResyncStale(query);
    }
    this.store.dispatch(new SetCurrentQuery(query));
  }

  setConversationIsLoading(value?: boolean): void {
    this.store.dispatch(new IsConversationLoadingAction(value));
  }

  setMessageIsLoading(): void {
    this.store.dispatch(new IsLoadingAction());
  }

  addTab(data): void {
    console.log("[convRepoTab] addTab ", data);
    // this.store.dispatch(new AddTab(data));
    if (!!data && !!data.l && !data.su) {
      this.retrieveMessagesById(data.id).pipe(take(1)).subscribe(msg => {
        if (!!msg && (msg.length > 0)) {
          const m = msg[0];
          if (!!m && !!m.l && !!m.su) {
            this.store.dispatch(new AddTab(m));
          } else {
            this.store.dispatch(new AddTab(data));
          }
        } else {
          this.store.dispatch(new AddTab(data));
        }
      });
    } else {
      this.store.dispatch(new AddTab(data));
    }
  }

  updateFirstTab(data): void {
    console.log("[convRepoTab] AddFirstTab ", data);
    if (!!data && !!data.l && !data.su) {
      this.retrieveMessagesById(data.id).pipe(take(1)).subscribe(msg => {
        if (!!msg && (msg.length > 0)) {
          const m = msg[0];
          if (!!m && !!m.l && !!m.su) {
            this.store.dispatch(new AddFirstTab(m));
          } else {
            this.store.dispatch(new AddFirstTab(data));
          }
        } else {
          this.store.dispatch(new AddFirstTab(data));
        }
      });
    } else {
      this.store.dispatch(new AddFirstTab(data));
    }
  }

  removeTab(data): void {
    console.log("[convRepoTab] RemoveTab  ", data);
    this.store.dispatch(new RemoveTab(data));
  }

  removeMultipleTabs(ids: string[]): void {
    console.log("[convRepoTab] RemoveMultipleTabs  ", ids);
    this.store.dispatch(new RemoveMultipleTabs(ids));
  }

  updateLastConversationData(data: any): void {
    this.store.dispatch(new UpdateLastConversationData(data));
  }

  getIsConversationLoading(): Observable<boolean> {
    return this.store.select(getIsConversationLoading);
  }

  getMailTabs(): Observable<any[]> {
    return this.store.select(getMailTabs);
  }

  getSelectedTab(): Observable<any> {
    return this.store.select(getSelectedTab);
  }

  getLastConversationData(): Observable<any> {
    return this.store.select(getLastConversationData);
  }

  getLastConversationDataById(id: string): Observable<any> {
    return this.store.select(state => getLastConversationDataById(state, id));
  }

  getIsMessageLoading(): Observable<boolean> {
    return this.store.select(getIsMailsLoading);
  }

  getHasMoreConversations(): Observable<boolean> {
    return this.store.select(getHasMoreConversations);
  }

  getSelectedConversationIds(): Observable<string[]> {
    return this.store.select(getSelectedConversationIds);
  }

  getCheckedConversationIds(): Observable<string[]> {
    return this.store.select(getCheckedConversationIds);
  }

  getSelectedMessageIds(): Observable<string[]> {
    return this.store.select(getSelectedMessageIds);
  }

  getCheckedMessageIds(): Observable<string[]> {
    return this.store.select(getCheckedMessageIds);
  }

  setConversationLoadedSuccess(conversations: Conversation[]): void {
    this.store.dispatch(new LoadConversationSuccessAction(conversations));
  }

  setConversationLoadedFail(): void {
    this.store.dispatch(new LoadConversationFailedAction());
  }

  addMessagesToStore(messages: Message[]): void {
    this.store.dispatch(new LoadMailSuccessAction(messages));
  }

  setMessageLoadedFail(): void {
    this.store.dispatch(new LoadMailFailedAction());
  }

  selectConversation(conversationId: string): void {
    this.store.dispatch(new SelectConversationAction(conversationId));
  }

  selectMessage(messageId: string): void {
    this.store.dispatch(new SelectMessageAction(messageId));
  }

  setMailDetailId(conversationId: string): void {
    this.store.dispatch(new SetMailDetailId(conversationId));
  }

  setMessageDetailId(messageId: string): void {
    this.store.dispatch(new SetMessageDetailId(messageId));
  }

  getMailDetailId(): Observable<string> {
    return this.store.select(getMailDetailId);
  }

  getMessageDetailId(): Observable<string> {
    return this.store.select(getMessageDetailId);
  }

  checkConversation(conversationId: string): void {
    this.store.dispatch(new CheckConversationAction(conversationId));
  }

  checkMessage(messageId: string): void {
    this.store.dispatch(new CheckMessageAction(messageId));
  }

  unSelectConversation(conversationId: string): void {
    this.store.dispatch(new UnSelectConversationAction(conversationId));
  }

  unSelectMessage(messageId: string): void {
    this.store.dispatch(new UnSelectMessageAction(messageId));
  }

  unCheckConversation(conversationId: string): void {
    this.store.dispatch(new UnCheckConversationAction(conversationId));
  }

  unCheckMessage(messageId: string): void {
    this.store.dispatch(new UnCheckMessageAction(messageId));
  }

  resetSelectedConversationIds(): void {
    this.store.dispatch(new ResetSelectedConversationsAction());
  }

  resetSelectedMessageIds(): void {
    this.store.dispatch(new ResetSelectedMessagesAction());
  }

  resetCheckedMessageIds(): void {
    this.store.dispatch(new ResetCheckedMessagesAction());
  }

  resetConversationsByFolder(query: string): void {
    this.store.dispatch(new ResetConversationsByFolderAction(query));
  }

  resetMessagesByFolder(query?: string): void {
    this.store.dispatch(new ResetMessagesAction());
  }

  resetConversations(): void {
    this.store.dispatch(new ResetConversationsAction());
  }

  resetCheckedConversationIds(): void {
    this.store.dispatch(new ResetCheckedConversationsAction());
  }

  multipleCheckConversationIds(conversationIds: string[]): void {
    this.store.dispatch(new MultipleCheckConversationsAction(conversationIds));
  }

  checkAllMessages(ids: string[]): void {
    this.store.dispatch(new CheckAllMessagesAction(ids));
  }


  // Read/unread
  //

  markReadUnreadMessages(ids: string[], isMarkRead: boolean) {
    console.log("[ConversationRepository][markReadUnreadMessages] ", ids);
    this.messageAction({ id: ids.toString(), op: isMarkRead ? "read" : "!read" }).subscribe(res => {
      const tempMessages: Message[] = [];
      this.getMessagesByIds(ids).pipe(take(1)).subscribe(messages => {
        messages.filter(msg => !!msg).forEach(msg => {
          if (isMarkRead) {
            msg = this.updateReadMessageFields(msg);
          } else {
            msg = this.updateUnreadMessageFields(msg);
          }

          this.getConversationsByIds([msg.cid]).pipe(take(1)).subscribe(conversations => {
            conversations.filter(conv => !!conv).forEach(conv => {
              conv.m.forEach(m => {
                if (m.id === msg.id) {
                  m.f = msg.f;
                }
              });
            });
          });
          tempMessages.push(msg);
        });
      });
      this.callNoOpRequest();
      this.store.dispatch(new UpdateManyMessagesSuccess(tempMessages));
    });

    // remove notifications
    if (isMarkRead && environment.isCordova) {
      ids.forEach(mid => {
          window.FirebasePlugin.clearMailNotification(mid, () => {
            console.log("[ConversationRepository][markReadUnreadMessages][clearMailNotification] success", mid);
          }, error => {
            console.error("[ConversationRepository][clearMailNotification][clearMailNotification] error", error, mid);
          });
      });
    }
  }

  markReadUnreadConversations(ids: string[], isMarkRead: boolean) {
    console.log("[ConversationRepository][markReadUnreadConversations] ids: ", ids, isMarkRead);
    ids = ids.filter(id => !id.startsWith("m_"));
    this.conversationAction({ id: ids.toString(), op: isMarkRead ? "read" : "!read" }).subscribe(res => {
      const tempConversations: Conversation[] = [];
      this.getConversationsByIds(ids).pipe(take(1)).subscribe(conversations => {
        conversations.filter(conv => !!conv).forEach(conv => {
          if (isMarkRead) {
            conv = this.updateReadConversationFields(conv);
          } else {
            conv = this.updateUnreadConversationFields(conv);
          }

          tempConversations.push(conv);
          this.messageReadUnreadFromConv(conv.m , isMarkRead, conv.id);
        });
      });
      this.callNoOpRequest();
      this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
    });

    // remove notifications
    if (isMarkRead && environment.isCordova) {
      ids.forEach(cid => {
          window.FirebasePlugin.clearAllMailNotificationsForConv(cid, () => {
            console.log("[ConversationRepository][markReadUnreadConversations][clearAllMailNotificationsForConv] success", cid);
          }, error => {
            console.error("[ConversationRepository][markReadUnreadConversations][clearAllMailNotificationsForConv] error", error, cid);
          });
      });
    }
  }

  private updateReadMessageFields(msg: Message): Message {
    if (msg.f) {
      msg.f = msg.f.replace("u", "");
    }

    return msg;
  }

  private updateReadConversationFields(conv: Conversation): Conversation {
    if (!!conv) {
      conv.u = 0;
      if (conv.f) {
        conv.f = conv.f.replace("u", "");
      }
    }

    return conv;
  }

  private updateUnreadMessageFields(msg: Message): Message {
    if (!msg.f) {
      msg.f = "u";
    } else if (msg.f.indexOf("u") === -1) {
      msg.f = msg.f + "u";
    }

    return msg;
  }

  private updateUnreadConversationFields(conv: Conversation): Conversation {
    if (conv) {
      conv.u = 1;
      if (!conv.f) {
        conv.f = "u";
      } else if (conv.f.indexOf("u") === -1) {
        conv.f = conv.f + "u";
      }
    }

    return conv;
  }


  // Spam/unspam
  //

  markAsSpamConversation(ids: string[]): Observable<string[]> {
    const response = new Subject<string[]>();

    if (!this.isOnline) {
      // check pending ops if the mail is still pending to be marked as spam
      this.databaseService.getAllPendingOperations().subscribe(ops => {
        if (ops.length > 0) {
          ops.forEach(operation => {
            if (operation.op === "!spam") {
              let opIds = operation.objectId.split(",");
              let newOpIds = opIds.filter(i => ids.indexOf(i) === -1);
              let existingIds = opIds.filter(i => ids.indexOf(i) !== -1);
              console.log("[markAsSpam] ", operation, newOpIds);
              if (existingIds.length > 0) {
                this.databaseService.deletePendingOperation(operation.id).subscribe(() => {
                  if (newOpIds.length > 0) {
                    const newBody = {
                      op: "!spam",
                      id: newOpIds.join(",")
                    };
                    const request = {
                      "url": "/api/msgAction",
                      "method": "post",
                      "body": body
                    };
                    this.databaseService.addPendingOperation(newBody.id, newBody.op, request).subscribe();
                  }
                });
              }
            }
          });
          const body = {
            op: "spam",
            id: ids.join(",")
          };
          this.conversationAction(body).subscribe(res => {
            this.store.dispatch(new RemoveManyConversations(ids));
            this.callNoOpRequest();
            response.next(ids);
          });
        } else {
          const body = {
            op: "spam",
            id: ids.join(",")
          };
          this.conversationAction(body).subscribe(res => {
            this.store.dispatch(new RemoveManyConversations(ids));
            this.callNoOpRequest();
            response.next(ids);
          });
        }
      });

    } else {

      const body = {
        op: "spam",
        id: ids.join(",")
      };
      this.conversationAction(body).subscribe(res => {
        this.store.dispatch(new RemoveManyConversations(ids));
        this.callNoOpRequest();
        response.next(ids);
      });
    }

    return response.asObservable();
  }

  markAsSpamMessage(ids: string[]): Observable<string[]> {
    const response = new Subject<string[]>();

    if (!this.isOnline) {
      // check pending ops if the mail is still pending to be marked as spam
      this.databaseService.getAllPendingOperations().subscribe(ops => {
        if (ops.length > 0) {
          ops.forEach(operation => {
            if (operation.op === "!spam") {
              let opIds = operation.objectId.split(",");
              let newOpIds = opIds.filter(i => ids.indexOf(i) === -1);
              let existingIds = opIds.filter(i => ids.indexOf(i) !== -1);
              console.log("[markAsSpam] ", operation, newOpIds);
              if (existingIds.length > 0) {
                this.databaseService.deletePendingOperation(operation.id).subscribe(() => {
                  if (newOpIds.length > 0) {
                    const newBody = {
                      op: "!spam",
                      id: newOpIds.join(",")
                    };
                    const request = {
                      "url": "/api/msgAction",
                      "method": "post",
                      "body": body
                    };
                    this.databaseService.addPendingOperation(newBody.id, newBody.op, request).subscribe();
                  }
                });
              }
            }
          });
          const body = {
            op: "spam",
            id: ids.join(",")
          };
          this.messageAction(body).subscribe(res => {
            this.store.dispatch(new RemoveManyMessages(ids));
            this.removeMessageFromConvRedux(ids);

            this.callNoOpRequest();
            response.next(ids);
          });
        } else {
          const body = {
            op: "spam",
            id: ids.join(",")
          };
          this.messageAction(body).subscribe(res => {
            this.store.dispatch(new RemoveManyMessages(ids));
            this.removeMessageFromConvRedux(ids);

            this.callNoOpRequest();
            response.next(ids);
          });

        }
      });

    } else {
      const body = {
        op: "spam",
        id: ids.join(",")
      };
      this.messageAction(body).subscribe(res => {
        this.store.dispatch(new RemoveManyMessages(ids));
        this.removeMessageFromConvRedux(ids);

        this.callNoOpRequest();
        response.next(ids);
      });
    }

    return response.asObservable();
  }

  markAsNotSpamConversation(ids: string[]): Observable<string[]> {
    const response = new Subject<string[]>();

    if (!this.isOnline) {
      // check pending ops if the mail is still pending to be marked as spam
      this.databaseService.getAllPendingOperations().subscribe(ops => {
        if (ops.length > 0) {
          ops.forEach(operation => {
            if (operation.op === "spam") {
              let opIds = operation.objectId.split(",");
              let newOpIds = opIds.filter(i => ids.indexOf(i) === -1);
              let existingIds = opIds.filter(i => ids.indexOf(i) !== -1);
              console.log("[markAsSpam] ", operation, newOpIds);
              if (existingIds.length > 0) {
                this.databaseService.deletePendingOperation(operation.id).subscribe(() => {
                  if (newOpIds.length > 0) {
                    const newBody = {
                      op: "spam",
                      id: newOpIds.join(",")
                    };
                    const request = {
                      "url": "/api/msgAction",
                      "method": "post",
                      "body": body
                    };
                    this.databaseService.addPendingOperation(newBody.id, newBody.op, request).subscribe();
                  }
                });
              }
            }
          });
          const body = {
            op: "!spam",
            id: ids.join(",")
          };
          this.conversationAction(body).subscribe(res => {
            this.store.dispatch(new RemoveManyConversations(ids));
            this.callNoOpRequest();
            response.next(ids);
          });
        } else {
          const body = {
            op: "!spam",
            id: ids.join(",")
          };
          this.conversationAction(body).subscribe(res => {
            this.store.dispatch(new RemoveManyConversations(ids));
            this.callNoOpRequest();
            response.next(ids);
          });
        }
      });

    } else {
      const body = {
        op: "!spam",
        id: ids.join(",")
      };
      this.conversationAction(body).subscribe(res => {
        this.store.dispatch(new RemoveManyConversations(ids));
        this.callNoOpRequest();
        response.next(ids);
      });
    }


    return response.asObservable();
  }

  markAsNotSpamMessage(ids: string[]): Observable<string[]> {
    const response = new Subject<string[]>();

    if (!this.isOnline) {
      // check pending ops if the mail is still pending to be marked as spam
      this.databaseService.getAllPendingOperations().subscribe(ops => {
        if (ops.length > 0) {
          ops.forEach(operation => {
            if (operation.op === "spam") {
              let opIds = operation.objectId.split(",");
              let newOpIds = opIds.filter(i => ids.indexOf(i) === -1);
              let existingIds = opIds.filter(i => ids.indexOf(i) !== -1);
              console.log("[markAsNotSpam] ", operation, newOpIds);
              if (existingIds.length > 0) {
                this.databaseService.deletePendingOperation(operation.id).subscribe(() => {
                  if (newOpIds.length > 0) {
                    const newBody = {
                      op: "spam",
                      id: newOpIds.join(",")
                    };
                    const request = {
                      "url": "/api/msgAction",
                      "method": "post",
                      "body": body
                    };
                    this.databaseService.addPendingOperation(newBody.id, newBody.op, request).subscribe();
                  }
                });
              }
            }
          });
          const body = {
            op: "!spam",
            id: ids.join(",")
          };
          this.messageAction(body).subscribe(res => {
            this.store.dispatch(new RemoveManyMessages(ids));
            this.removeMessageFromConvRedux(ids);

            this.callNoOpRequest();
            response.next(ids);
          });
        } else {
          const body = {
            op: "!spam",
            id: ids.join(",")
          };
          this.messageAction(body).subscribe(res => {
            this.store.dispatch(new RemoveManyMessages(ids));
            this.removeMessageFromConvRedux(ids);

            this.callNoOpRequest();
            response.next(ids);
          });

        }
      });

    } else {
      const body = {
        op: "!spam",
        id: ids.join(",")
      };
      this.messageAction(body).subscribe(res => {
        this.store.dispatch(new RemoveManyMessages(ids));
        this.removeMessageFromConvRedux(ids);

        this.callNoOpRequest();
        response.next(ids);
      });
    }

    return response.asObservable();
  }


  // Trash
  //

  moveToTrashConversations(ids: string[], fromDrafts: boolean): Observable<string[]> {
    console.log("[ConversationRepository][moveToTrashConversations]", ids);

    const response = new Subject<string[]>();
    const body = {
      op: "trash",
      l: MailConstants.FOLDER_ID.TRASH,
      id: ids.join(",")
    };
    if (!fromDrafts) {
      this.conversationAction(body).subscribe(res => {
        this.store.dispatch(new RemoveManyConversations(ids));
        this.callNoOpRequest();
        response.next(ids);
      });
    } else {
      this.messageAction(body).subscribe(res => {
        this.store.dispatch(new RemoveManyConversations(ids));
        this.callNoOpRequest();
        response.next(ids);
      });
    }

    // here we also need to move all conv's message to trash as well
    ids.forEach(cid => {
      this.getMessagesByCidFromDB(cid).subscribe(messages => {
        const mids = messages.map(m => m.id);
        if (mids && mids.length > 0) {
            const body = {
              op: "trash",
              l: MailConstants.FOLDER_ID.TRASH,
              id: mids.join(",")
            };
          console.log("[conversation.repo][moveToTrashConv][this.messageActionApplyToDB]");
            this.messageActionApplyToDB(body);
        }
      });
    });
    return response.asObservable();
  }

  moveToTrashMessages(ids: string[]): Observable<string[]> {
    console.log("[ConversationRepository][moveToTrashMessages]", ids);

    const response = new Subject<string[]>();
    const body = {
      op: "trash",
      l: MailConstants.FOLDER_ID.TRASH,
      id: ids.join(",")
    };
    this.messageAction(body).subscribe(res => {
      this.store.dispatch(new RemoveManyMessages(ids));
      this.removeMessageFromConvRedux(ids);
      this.callNoOpRequest();
      response.next(ids);
    });
    return response.asObservable();
  }


  // Delete
  //

  deleteConversations(ids: string[]): Observable<string[]> {
    const response = new Subject<string[]>();
    const body = {
      op: "delete",
      id: ids.join(",")
    };
    this.conversationAction(body).subscribe(res => {
      this.store.dispatch(new RemoveManyConversations(ids));
      this.callNoOpRequest();
      response.next(ids);
    });
    return response.asObservable();
  }

  deleteMessages(ids: string[]): Observable<string[]> {
    const response = new Subject<string[]>();
    const body = {
      op: "delete",
      id: ids.join(",")
    };
    this.messageAction(body).subscribe(res => {
      this.store.dispatch(new RemoveManyMessages(ids));
      this.removeMessageFromConvRedux(ids);

      this.callNoOpRequest();
      response.next(ids);
    });
    return response.asObservable();
  }


  // Move
  //

  moveToFolder(ids: string[], folderId: string, isConversation?: boolean, isUndo?: boolean): Observable<string[]> {
    if (!this.isOnline) {
      if (!!isUndo) {
        console.log("[ConversationRepository][moveToFolder] -> getAllPendingOperations, offline undo ", ids);
        this.databaseService.getAllPendingOperations().subscribe(ops => {
         if (ops.length > 0) {
           ops.forEach(operation => {
             if (operation.op === "trash" || operation.op === "move") {
               let opIds = operation.objectId.split(",");
               let newOpIds = opIds.filter(i => ids.indexOf(i) === -1);
               let existingIds = opIds.filter(i => ids.indexOf(i) !== -1);
               console.log("[moveToFolder undo trash] ", operation, newOpIds, existingIds);
               if (existingIds.length > 0) {
                 this.databaseService.deletePendingOperation(operation.id).subscribe(() => {
                   if (newOpIds.length > 0) {
                     const newBody = {
                       op: "operation.op",
                       l: operation.request.body.l,
                       id: newOpIds.join(",")
                     };
                     const request = {
                       "url": "/api/msgAction",
                       "method": "post",
                       "body": newBody
                     };
                     this.databaseService.addPendingOperation(newBody.id, newBody.op, request).subscribe();
                   }
                 });
               }
             }
           });
         }
        });
      }
    }
    if (!isConversation) {
      return this.moveToFolderMessages(ids, folderId, isUndo);
    } else {
      return this.moveToFolderConversations(ids, folderId, isUndo);
    }
  }

  private moveToFolderConversations(ids: string[], folderId: string, isUndo?: boolean) {
    console.log("[ConversationRepository][moveToFolderConversations]", ids, "folder: " + folderId);
    const response = new Subject<string[]>();

    const body = {
      op: "move",
      id: ids.join(","),
      l: folderId
    };

    this.conversationAction(body, isUndo).subscribe(res => {
      this.store.dispatch(new RemoveManyConversations(ids));
      this.databaseService.moveConversationsBetweenFolders(ids, folderId).subscribe(responseItem => {
        // this.databaseService.deleteMessages(ids).subscribe(responseItem => { });
        this.callNoOpRequest();
        response.next(ids);
      });
    });

    // here we also need to move all conv's message as well
    ids.forEach(cid => {
      this.getMessagesByCidFromDB(cid).subscribe(messages => {
        const mids = messages.map(m => m.id);
        if (mids.length > 0) {
          const body = {
            op: "move",
            id: mids.join(","),
            l: folderId
          };
          this.messageActionApplyToDB(body).subscribe();
        }
      });
    });

    return response.asObservable();
  }

  private moveToFolderMessages(ids: string[], folderId: string, isUndo?: boolean) {
    console.log("[ConversationRepository][moveToFolderMessages]", ids, "folder: " + folderId);

    const response = new Subject<string[]>();

    const body = {
      op: "move",
      id: ids.join(","),
      l: folderId
    };

    this.messageAction(body, isUndo).subscribe(res => {
      this.store.dispatch(new RemoveManyMessages(ids));
      this.removeMessageFromConvRedux(ids);
      // move, not delete!
      // this.databaseService.deleteMessages(ids).subscribe(responseItem => { });
      this.databaseService.moveMessagesBetweenFolders(ids, folderId).subscribe(responseItem => {
        // console.log("[ConversationRepository][moveMessagesBetweenFolders1]", responseItem);
        this.callNoOpRequest();
        response.next(ids);
      }, err => {
        this.callNoOpRequest();
        response.next(ids);
      });
    });

    return response.asObservable();
  }

  removeAllMailOnEmptyFolder(folderId?: string) {
    this.store.dispatch(new RemoveAllConversationsFromFolder(folderId));
  }

  removeAllMessagesOnEmptyFolder(folderId?: string) {
    this.store.dispatch(new RemoveMessagesFromFolder(folderId));
  }

  markReadAllMail() {
    this.store.select(getConversations).pipe(take(1)).subscribe(res => {
      this.conversations = res;
    });
    const tempConversations: Conversation[] = [];
    this.conversations.forEach(conv => {
      if (conv && conv.f && conv.f.indexOf("u") !== -1) {
        conv.f = conv.f.replace("u", "");
        tempConversations.push(conv);
      }
    });
    this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
  }

  printConversation(convId) {
    this.mailService.printConversation(convId);
  }

  updateConversationMessage(message: Message): void {
    this.getConversationById(message.cid).pipe(take(1)).subscribe(conv => {
        console.log("updateConversationMessage", message.cid, message, conv);
        if (!!conv) {
            conv.m.forEach(m => {
                if (m.id === message.id) {
                    m = {...m, ...message};
                    return;
                }
            });
            this.store.dispatch(new UpdateConversationSuccess({id: message.cid, changes: {m: conv.m}}));
        }
    });
  }

  updateConversationMessages(messages: Message[]): void {
    this.getConversationById(messages[0].cid).pipe(take(1)).subscribe(conv => {
      if (!!conv) {
        if (conv.m && conv.m.length === messages.length) {
          conv.m = conv.m.map(v => {
            if (messages.find(m => v.id === m.id)) {
              v = {...v, ...messages.find(m => v.id === m.id)};
            }
            return v;
          });
        } else {
          conv.m = messages;
        }
        this.databaseService.updateConversationMessages(messages[0].cid, conv.m);
        console.log("updateConversationMessages", messages[0].cid, messages, conv.m);
        this.store.dispatch(new UpdateConversationSuccess({id: messages[0].cid, changes: {m: conv.m}}));
      }
    });
  }


  // Flag/unflag convs

  flagConversation(conversation: Conversation) {
    const body = { id: conversation.id, op: "flag" };
    const tempConversations: Conversation[] = this.updateFlagConversationsFields([conversation.id]);
    this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
    this.toggleMessageFlag(conversation, "flag");
    this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MAIL, { flag: true, conversations: [conversation] });
    this.conversationAction(body).subscribe(res => {
      this.callNoOpRequest();
    }, err => {
      this.toggleMessageFlag(conversation, "!flag");
      this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MAIL, { flag: false, conversations: [conversation] });
      this.callNoOpRequest();
    });
  }

  unflagConversation(conversation: Conversation, isDraftFolder: boolean, isStarFolder) {
    const body = { id: conversation.id, op: "!flag" };
    const tempConversations: Conversation[] = this.updateUnFlagConversationsFields([conversation.id]);
    this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
    this.toggleMessageFlag(conversation, "!flag");
    this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MAIL, { flag: false, conversations: [conversation] });
    this.conversationAction(body).subscribe(res => {
        if (isStarFolder) {
          const ids = tempConversations.map(c => c.id);
          this.store.dispatch(new RemoveManyConversations(ids));
        }
        this.callNoOpRequest();
      }, err => {
        this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MAIL, { flag: true, conversations: [conversation] });
        this.toggleMessageFlag(conversation, "flag");
        this.callNoOpRequest();
      });
  }

  callNoOpRequest() {
    setTimeout(() => {
      console.log("[ConversationRepository] CALL_NO_OP_REQUEST");
      this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
    }, 2000);
  }

  flagMultipleConversation(conversation: Conversation[], isDraftFolder: boolean) {
    const ids = conversation.map(c => c.id);
    const body = { id: ids.toString(), op: "flag" };
    if (isDraftFolder) {
      this.messageAction(body).subscribe(res => {
        const tempConversations: Conversation[] = this.updateFlagConversationsFields(ids);
        this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
        this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MAIL, { flag: true, conversations: tempConversations });
      });
    } else {
      this.conversationAction(body).subscribe(res => {
        const tempConversations: Conversation[] = this.updateFlagConversationsFields(ids);
        this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
        this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MAIL, { flag: true, conversations: conversation });
      });
    }
  }

  unflagMultipleConversation(conversation: Conversation[], isDraftFolder: boolean, isStarFolder) {
    const ids = conversation.map(c => c.id);
    const body = { id: ids.toString(), op: "!flag" };
    if (isDraftFolder) {
      this.messageAction(body).subscribe(res => {
        const tempConversations: Conversation[] = this.updateUnFlagConversationsFields(ids);
        this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
        this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MAIL, { flag: false, conversations: conversation });
      });
    } else {
      this.conversationAction(body).subscribe(res => {
        const tempConversations: Conversation[] = this.updateUnFlagConversationsFields(ids);
        this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
        if (isStarFolder) {
          const id = tempConversations.map(c => c.id);
          this.store.dispatch(new RemoveManyConversations(id));
        }
        this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MAIL, { flag: false, conversations: conversation });
      });
    }
  }

  updateFlagConversationsFields(ids: string[], messageId?: string): Conversation[] {
    const tempConversations: Conversation[] = [];
    this.getConversationsByIds(ids).pipe(take(1)).subscribe(conver => {
      conver.filter(v => !!v).forEach(conv => {
        if (conv.f) {
          if (conv.f.indexOf("f") !== -1) {
            conv.f = conv.f.replace("f", "");
          }
          conv.f = conv.f + "f";
        } else {
          conv.f = "f";
        }
        if (messageId) {
          conv.m.filter(m => m.id === messageId).forEach(msg => {
            if (msg.f) {
              if ( msg.f.indexOf("f") !== -1 ) {
                msg.f = msg.f.replace("f", "");
              }
              msg.f = msg.f + "f";
            } else {
              msg.f = "f";
            }
          });
        } else {
          conv.m.forEach(msg => {
            if (msg.f) {
              if ( msg.f.indexOf("f") !== -1 ) {
                msg.f = msg.f.replace("f", "");
              }
              msg.f = msg.f + "f";
            } else {
              msg.f = "f";
            }
          });
        }
        tempConversations.push(conv);
      });
    });
    return tempConversations;
  }

  updateUnFlagConversationsFields(ids: string[], messageId?: string): Conversation[] {
    const tempConversations: Conversation[] = [];
    this.getConversationsByIds(ids).pipe(take(1)).subscribe(conver => {
      conver.filter(v => !!v).forEach(conv => {
        conv.f = conv.f.replace("f", "");
        if (!messageId) {
          conv.m.forEach(msg => {
            msg.f = msg.f ? msg.f.replace("f", "") : "";
          });
        } else {
          conv.m.filter(m => m.id === messageId).forEach(msg => {
            msg.f = msg.f ? msg.f.replace("f", "") : "";
          });
        }
        tempConversations.push(conv);
      });
    });
    return tempConversations;
  }


  // Flag/unflag messages
  //

  flagMessage(message: Message, isDraftFolder: boolean) {
    const body = { id: message.id, op: "flag" };
    this.messageAction(body).subscribe(res => {
      const tempMessages: Message[] = this.updateFlagMessages([message.id]);
      this.store.dispatch(new UpdateManyMessagesSuccess(tempMessages));
      this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MESSAGE, { flag: true, message: message });
      this.callNoOpRequest();
      this.updateConversationMessage(tempMessages[0]);
    });
  }

  unflagMessage(message: Message, isDraftFolder: boolean, isStarFolder) {
    const body = { id: message.id, op: "!flag" };
    this.messageAction(body).subscribe(res => {
      const tempMessages: Message[] = this.updateUnFlagMessages([message.id]);
      this.store.dispatch(new UpdateManyMessagesSuccess(tempMessages));
      this.callNoOpRequest();
      this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MESSAGE, { flag: false, message: message });
      this.updateConversationMessage(tempMessages[0]);
      if (isStarFolder) {
        this.store.dispatch(new RemoveManyMessages([message.id]));
      }
    });
  }

  flagMultipleMessage(message: Message[], isDraftFolder: boolean) {
    const ids = message.map(m => m.id);
    const body = { id: ids.toString(), op: "flag" };
    this.messageAction(body).subscribe(res => {
      const tempMessages: Message[] = this.updateFlagMessages(ids);
      this.store.dispatch(new UpdateManyMessagesSuccess(tempMessages));
      this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MESSAGE, { flag: true, messages: tempMessages });
      this.updateConversationMessages(tempMessages);
    });
  }

  unflagMultipleMessage(message: Message[], isDraftFolder: boolean, isStarFolder) {
    const ids = message.map(m => m.id);
    const body = { id: ids.toString(), op: "!flag" };
    this.messageAction(body).subscribe(res => {
      const tempMessages: Message[] = this.updateUnFlagMessages(ids);
      this.store.dispatch(new UpdateManyMessagesSuccess(tempMessages));
      this.updateConversationMessages(tempMessages);
      this.mailBroadcaster.broadcast(MailConstants.STAR_UNSTAR_MESSAGE, { flag: false, messages: tempMessages });
      if (isStarFolder) {
        this.store.dispatch(new RemoveManyMessages(ids));
      }
    });
  }

  private updateFlagMessages(ids: string[]): Message[] {
    const tempMessages: Message[] = [];
    this.getMessagesByIds(ids).pipe(take(1)).subscribe(messages => {
      console.log("[ConversationRepository][updateFlagMessages] messages", ids, messages);
      messages.forEach(msg => {
        if (msg.f) {
          if (msg.f.indexOf("f") !== -1 ) {
            msg.f = msg.f.replace("f", "");
          }
          msg.f = msg.f + "f";
        } else {
          msg.f = "f";
        }
        tempMessages.push(msg);
      });
    });
    return tempMessages;
  }

  private updateUnFlagMessages(ids: string[]): Message[] {
    const tempMessages: Message[] = [];
    this.getMessagesByIds(ids).pipe(take(1)).subscribe(messages => {
      messages.filter(v => !!v).forEach(msg => {
        msg.f = msg.f.replace("f", "");
        tempMessages.push(msg);
      });
    });
    return tempMessages;
  }

  //
  //

  getAvatar(email: string) {
    if (!email) {
      return null;
    }
    if (this.currentUser && this.currentUser.email === email && this.currentUser.imageData) {
      return this.currentUser.imageData;
    }
    const contact = this.contactProfiles.find(c => c.emails === email);
    if (contact) {
      return contact.blobImage ? contact.blobImage : contact.avatarUrl;
    }
    return null;
  }

  downloadFileToDownloadsOrGallery(fileBlob: Blob, fileName: string): Observable<any> {
    console.log("[ConversationRepository] downloadFileToDownloadsOrGallery fileName: ", fileName);

    const response = new Subject();

    if (CommonUtils.isOnIOS()) {
      // save in hidden cache
      this.filesStorageService.saveBlobToDisc(fileBlob, fileName).subscribe((localFileUrl) => {
        // for iOS we need to additionaly save to Camera Roll
        this.toastService.show("DOWNLOAD_SUCCESS");
        cordova.plugins.imagesaver.saveImageToGallery(localFileUrl, () => {
          console.log("[ConversationRepository] downloadFileToDownloadsOrGallery success, localFileUrl: ", localFileUrl);
          response.next(localFileUrl);
        }, (err) => {
          response.error(err);
        });
      }, err => {
        response.error(err);
      });

      // Android
    } else {
      this.filesStorageService.saveBlobToAndroidDownloadFolder(fileBlob, fileName).subscribe((localFileUrl) => {
        this.toastService.show("DOWNLOAD_SUCCESS");
        console.log("[ConversationRepository] downloadFileToDownloadsOrGallery success, localFileUrl: ", localFileUrl);
        response.next(localFileUrl);
      }, err => {
        response.error(err);
      });
    }
    return response.asObservable().pipe(take(1));
  }

  addMessageToConversation(message: Message[]): void {
    const tempConversations: Conversation[] = [];
    message.map(msg => {
      this.getConversationsByIds([msg.cid]).pipe(take(1)).subscribe(conversations => {
        conversations.filter(conv => !!conv).forEach(conv => {
        if (!_.find(conv.m, {id: msg.id})) {
            conv.m.push(msg);
        }
        tempConversations.push(conv);
        });
      });
    });
    this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
  }

  getSortValue(): string {
    let sort = "desc";
    this.getExpandConversation().pipe(take(1)).subscribe(value => {
      if (value === "old") {
        sort = "asc";
      }
    });
    return sort;
  }

  removeMessageFromConvRedux(ids: string[]): Conversation[] {
    const tempConversations: Conversation[] = [];

    // get convs by messages
    const convIds = [];
    this.getMessagesByIds(ids).pipe(take(1)).subscribe( msgs => {
      msgs.filter(v => !!v).map(item => {
        if (convIds.indexOf(item.cid) === -1) {
          convIds.push(item.cid);
        }
      });
    });

    const convIdsToDelete = [];
    try {
      this.getConversationsByIds(convIds).pipe(take(1)).subscribe(conversations => {
        conversations.filter(conv => !!conv).forEach(conv => {
          conv.m = conv.m.filter(m => ids.indexOf(m.id) === -1); // get messages that are not in 'ids'
          if (conv.m.length === 0) {
            convIdsToDelete.push(conv.id);
          } else {
            tempConversations.push(conv);
          }
        });
      });
    } catch (error) {
      console.log("error: entities... ", error);
    }

    this.store.dispatch(new UpdateManyConversationsSuccess(tempConversations));
    if (convIdsToDelete.length > 0) {
      this.store.dispatch(new RemoveManyConversations(convIdsToDelete));
    }

    return tempConversations;
  }

  getExpandConversation(): Observable<string> {
    return this.store.select(getExpandConversation);
  }

  updateMessages(message: Message[]): void {
    this.store.dispatch(new UpdateManyMessagesSuccess(message));
  }

  updateMessage(message: Message): void {
    this.store.dispatch(new UpdateManyMessagesSuccess([message]));
  }


  // Tag/untag convs
  //

  tagConversation(conversation: Conversation, tagName: string) {
    const body = { id: conversation.id, op: "tag", tn: tagName };
    this.conversationAction(body).subscribe(res => {
      conversation.tags.push(tagName);
      conversation.tn = conversation.tags.join(",");
      const tags = conversation.tags;
      const tn = conversation.tn;
      if (!!conversation.m) {
        conversation.m.forEach(m => {
          m.tn = m.tn ? `${m.tn},${tagName}` : tagName;
        });
      }
      this.callNoOpRequest();
      this.store.dispatch(new UpdateConversationSuccess({id: conversation.id, changes: {tags, tn, m: conversation.m}}));
    });
  }

  removeAllTagsInConversation(conversation: Conversation) {
    const body = { id: conversation.id, op: "update", t: "" };
    this.conversationAction(body).subscribe(res => {
      if (conversation.m) {
        conversation.m = conversation.m.map(m => {
          return this.clearTagsInObject(m);
        });
      }
      this.store.dispatch(new UpdateConversationSuccess({id: conversation.id, changes: {tags: [], t: "", tn: "", m: conversation.m}}));
      this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: conversation.m});
    });
  }


  // Tag/untag messages
  //

  removeAllTagsInMessage(msg: Message): Observable<any> {
    const response = new Subject<any>();

    const body = { id: msg.id, op: "update", tn: ""};
    this.messageAction(body).subscribe(res => {

      msg = this.clearTagsInObject(msg);

      this.store.dispatch(new UpdateManyMessagesSuccess([msg]));
      this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: [msg]});

      response.next(res);
    });

    return response.asObservable().pipe(take(1));
  }

  removeTagInMessage(messageId: string, tagName: string): Observable<any> {
    const response = new Subject<any>();

    const body = { id: messageId, op: "!tag", tn: tagName };

    this.messageAction(body).subscribe(res => {
      response.next(res);
    });

    return response.asObservable().pipe(take(1));
  }

  private clearTagsInObject(data: any): Message {
    data.tags =  [];
    data.t = "";
    data.tn = "";

    return data;
  }

  addTagToObject(data: any, tag: MailTag) {  // object is Message or Conversation
    let changed = false;

    // tag id
    if (!this.configService.useVNCdirectoryAuth) {
      if (data.t) {
        const t = data.t.split(",");
        if (t.indexOf(tag.id) === -1) {
          t.push(tag.id);
        }
        data.t = t.join(",");
        changed = true;
      } else {
        data.t = tag.id;
        changed = true;
      }
    }

    // tag name
    if (data.tn) {
      const t = data.tn.split(",");
      if (t.indexOf(tag.name) === -1) {
        t.push(tag.name);
      }
      data.tn = t.join(",");
      changed = true;
    } else {
      data.tn = tag.name;
      changed = true;
    }

    if (!data.tags) {
      data.tags = [tag.name];
    } else {
      const tagIndex3 = data.tags.findIndex((v) => v === tag.name);
      if (tagIndex3 === -1) {
        data.tags.push(tag.name);
      }
    }

    // if it's conv
    if ((data as Conversation).m) {
      (data as Conversation).m.forEach(m => {
        this.addTagToObject(m, tag);
      });
    }

    if (changed) {
      return data;
    }
    return null;
  }

  addTagToMessagesIds(ids: string[], tag: MailTag): Message[] {
    let updatedMessages: Message[];
    this.getMessagesByIds(ids).pipe(take(1)).subscribe(messages => {
      updatedMessages = messages.map(msg => {
        const processedMsg = this.addTagToObject(msg, tag);
        return processedMsg ? processedMsg : msg;
      });
    });
    return updatedMessages;
  }

  addTagToConversationsIds(ids: string[], tag: MailTag) {
    let updatedConvs: Conversation[];
    this.getConversationsByIds(ids).pipe(take(1)).subscribe(convs => {
      updatedConvs = convs.map(conv => {
        const processedConv = this.addTagToObject(conv, tag);
        return processedConv ? processedConv : conv;
      });
    });
    return updatedConvs;
  }

  removeTagFromObject(data: any, tag: MailTag) { // object is Message or Conversation
    const t = data.t.split(",");
    const tn = data.tn.split(",");

    // remove
    const tagIndex = t.findIndex((v) => v === tag.id);
    const tagIndex2 = tn.findIndex((v) => v === tag.name);
    const tagIndex3 = data.tags ? data.tags.findIndex((v) => v === tag.name) : -1;
    if (tagIndex !== -1) {
      t.splice(tagIndex, 1);
    }
    if (tagIndex2 !== -1) {
      tn.splice(tagIndex2, 1);
    }
    if (tagIndex3 !== -1) {
      data.tags.splice(tagIndex3, 1);
    }

    data.t = t.join(",");
    data.tn = tn.join(",");

    return data;
  }

  removeTagFromMessagesIds(ids: string[], tag: MailTag) {
    let updatedMessages: Message[];
    this.getMessagesByIds(ids).pipe(take(1)).subscribe(messages => {
      updatedMessages = messages.map(msg => {
        const processedMsg = this.removeTagFromObject(msg, tag);
        return processedMsg ? processedMsg : msg;
      });
    });
    return updatedMessages;
  }

  removeTagFromConversationsIds(ids: string[], tag: MailTag) {
    let updatedConvs: Conversation[];
    this.getConversationsByIds(ids).pipe(take(1)).subscribe(convs => {
      updatedConvs = convs.map(conv => {
        const processedConv = this.removeTagFromObject(conv, tag);
        return processedConv ? processedConv : conv;
      });
    });
    return updatedConvs;
  }

  //
  //


  messageReadUnreadFromConv(messages: Message[] , isMarkRead: boolean, conversationId: any): void {
    messages.filter(msg => !!msg).forEach(msg => {
      if (isMarkRead) {
        if (msg.f) {
          msg.f = msg.f.replace("u", "");
        }
      } else {
        if (!msg.f) {
          msg.f = "u";
        } else if (msg.f.indexOf("u") === -1) {
          msg.f = msg.f + "u";
        }
      }
    });
    this.updateMessages(messages);
  }

  printMessage(msgId) {
    this.mailService.printMessage(msgId);
  }

  printConvAndMessage(convIds: string[], msgIds: string[]) {
    this.mailService.printMessageAndConv(convIds.filter(id => !id.startsWith("m_")), msgIds);
  }

  showOriginal(id: string): void {
    this.mailService.getOriginalConversationText(id);
  }

  // updateConversationMessages(msgs: Message[]): void {
  //   msgs.map( msg => {
  //     this.getConversationById(msg.cid).pipe(take(1)).subscribe(conv => {
  //         if (!!conv) {
  //             conv.m.forEach(m => {
  //                 if (m.id === msg.id) {
  //                     m.t = msg.t;
  //                     m.tn = msg.tn;
  //                     m.f = msg.f;
  //                     return;
  //                 }
  //             });
  //             this.store.dispatch(new UpdateConversationSuccess({id: msg.cid, changes: {m: conv.m}}));
  //         }
  //     });
  //   });
  // }

  createAppointmentConversation(id: string): void {
    const body = { id: id };
    this.mailService.getConversations(body).pipe(take(1)).subscribe(conversations => {
      const conv = conversations.c;
      if (!!conv && conv[0].m) {
        const id = conv[0].m[0].id;
        this.createAppointmentFromMessage(id);
      }
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  createAppointmentFromMessage(id: string): void {
    this.retrieveMessagesById(id).pipe(take(1)).subscribe(res => {
      const message = res[0];
      this.opencreateAppointmentDialog(message);
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  opencreateAppointmentDialog(message: Message): void {
    const subject = message.su;
    const allAttendees = message.e.filter( e => e.a !== this.currentUser.email);
    const attendee: any [] = [];
    if (!!allAttendees) {
      allAttendees.map( item => {
        attendee.push(item.a);
      });
    }
    const mailBody = !!message.body ? message.body : MailUtils.getEmailBody(message);
    const data: ConfirmationData = {
      action: "confirm",
      titleKey: "ADD_ATTENDEES",
      contentKey: "CREATE_APPOINTMENT_MAIL_CONFIRM_MSG",
      cancelKey: "COMMON.NO",
      actionKey: "COMMON.YES"
    };
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: "calendar_confirmation_operation_dialog",
      width: "380px",
      data: data
    });
    console.log("opencreateAppointmentDialog", message);
    const attachments = (message.mp && Array.isArray(message.mp) && message.mp[0]) ? message.mp[0].mp : [];
    dialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
      if (!!resp) {
        let sentData: any;
        if (!resp.confirmed) {
          sentData = {
            messageId: message.id,
            subject: subject,
            mailBody: mailBody,
            attachments,
            startdate: message.startdate,
          };
        } else {
          sentData = {
            messageId: message.id,
            subject: subject,
            mailBody: mailBody,
            attendee: attendee,
            attachments,
            startdate: message.startdate,
          };
        }
        this.router.navigate(["/calendar"]);
        setTimeout(() => {
          this.mailBroadcaster.broadcast("CREATE_APPOINTMENT_FROM_MAIL", sentData );
        }, 2500);
      }
    });
  }

  copyContent(content: string): void {
    MailUtils.copyFromTextClipboard(content);
  }

  searchFromSender(email: string): void {
    const query = "from:" + email;
    this.navigateToSearch(query);
  }

  searchSentToSender(email: string): void {
    const query = "tocc:" + email;
    this.navigateToSearch(query);
  }

  navigateToSearch(query: string): void {
    this.router.navigate(["/mail/search"], { queryParams: { searchParams: btoa(query) } });
  }

  composeNewEmail(emailInfo: any): void {
    this.router.navigate(["/mail/compose"]);
    setTimeout(() => {
        this.mailBroadcaster.broadcast(MailConstants.TOOLTIP_COMPOSE, {
            info: emailInfo
        });
    }, 0);
  }


  // Pending ops
  //

  processPendingOperations(): Observable<any> {
    console.log("[ConversationRepository][processPendingOperations] ", this.isProcessingPendingOps);

    // notify when pending processing is finished
    const maybeNotifyPendingOperationsProcessingFinished = (opsProcessed, opsTotalLength, response) => {
      if (opsProcessed === opsTotalLength) {
        console.log("[ConversationRepository][processPendingOperations] DONE");
        this.isProcessingPendingOps = false;
        response.next(opsProcessed);
      }
    };

    const response = new Subject<any>();

    if (this.isProcessingPendingOps) {
      console.log("[ConversationRepository][processPendingOperations] locked, bailing out", this.isProcessingPendingOps);
      response.next(0);
      setTimeout(() => {
        this.mailBroadcaster.broadcast("BroadcastKeys.PROCESS_PENDING_OPERATIONS");
      }, 2000);
    } else {
      this.isProcessingPendingOps = true;

      this.databaseService.getAllPendingOperations().subscribe(ops => {
        console.log("[ConversationRepository][processPendingOperations] pendingStartOps: ", ops);
        if (ops.length === 0) {
          console.log("[ConversationRepository][processPendingOperations] nothing to proceed");
          this.isProcessingPendingOps = false;
          response.next(0);
          return;
        }

        let opsProcessed = 0;

        console.log("[ConversationRepository][processPendingOperations] ops", ops);

        ops.forEach(pop => {
          let request = pop.request;

          const method = request["method"];
          const url = request["url"];
          const body = request["body"];

          console.log("[ConversationRepository][processPendingOperations]", method, url, body);

          if (method === "post") {
            this.mailService.queryPost(url, body).pipe(map((res: any) => {
              console.log("[ConversationRepository][processPendingOperations] processpendingres", res);

              this.databaseService.deletePendingOperation(pop.id).subscribe(() => {
                console.log("[ConversationRepository][processPendingOperations] cleared", pop.id);

                if (pop.op === "move") {
                  console.log("[ConversationRepository][processPendingOperations] need to update folders", pop);
                  this.mailBroadcaster.broadcast("MAIL_FOLDER_UPDATE_AFTER_MOVE");
                }

                if (pop.op  === "sendEmail") {
                  if (!!body.attach.fid && body.attach.fid.length) {
                    this.databaseService.deleteAttachment(body.attach.fid).subscribe(() => {
                      console.log("[ConversationRepository][deleteAttachment]", body.attach.fid);
                    });
                  }
                  this.discardDraft(pop.objectId).subscribe(() => {
                    console.debug("[ConversationRepository][deletePendingOperation][discardMail] done", pop.objectId);
                }, err => {
                    console.error("[ConversationRepository][deletePendingOperation][discardMail] err", err);
                }); // delete draft message
                  // remove local objects with fake id from DB & Redux
                  this.databaseService.deleteMessage(pop.objectId).subscribe(delRes => { });
                  this.store.dispatch(new RemoveManyMessages([pop.objectId]));
                  this.databaseService.deleteConversation(pop.objectId).subscribe(delRes => { });
                  this.store.dispatch(new RemoveManyConversations([pop.objectId]));

                  // get complete message and store in DB
                  if (pop.op  === "sendEmail") {
                    // TODO: it conflicts with other query here
                    // it's better to use transactions instead of timeout
                    setTimeout(() => {
                      this.retrieveMessagesById(res.m[0].id).subscribe();
                    }, 2000);
                  }
                }
                if (pop.op === "createAppointment" || pop.op === "modifyAppointment" || pop.op === "createAppointmentException") {
                  if (pop.objectId.indexOf("instance_after") === -1) {
                    this.databaseService.deleteAppointments([pop.objectId]).subscribe(delRes => { });
                    this.store.dispatch(new DeleteCalendarAppointmentsSuccessAction({appointmentIds: [pop.objectId]}));
                  }
                }

                if (pop.op === "modifyPrefs") {
                  if (body.prefs) {
                    this.preferenceRepo.updatePreferences(body.prefs);
                  }
                }

                // done ?
                ++opsProcessed;
                console.log("[ConversationRepository][processPendingOperations] opsProcessed", opsProcessed, ops.length );
                maybeNotifyPendingOperationsProcessingFinished(opsProcessed, ops.length, response);

              });
            }), catchError(error => {
              console.error("[ConversationRepository][processPendingOperations]", error, pop);
              if ((error.indexOf("no such message") > -1) || (error.indexOf("malformed item ID") > -1)) {
                this.databaseService.deletePendingOperation(pop.id).subscribe(() => {
                  console.log("[ConversationRepository][processPendingOperations] cleared", pop.id);
                });
              }

              // done ?
              ++opsProcessed;
              maybeNotifyPendingOperationsProcessingFinished(opsProcessed, ops.length, response);

              return null;
            })
          ).subscribe();
          }
        });
      });
    }


    return response.asObservable().pipe(take(1));
  }

  conversationAction(body, isUndo?: boolean): Observable<any> {
    console.log("[ConversationRepository][conversationAction]", body);

    const response = new Subject<any>();
    if (body.op === "trash" || body.op === "move" && body.l === "3") {
      this.addConversationOrMessageToTrash(body.id);
      this.deleteConversationsFromDB([body.id]);
      this.store.dispatch(new RemoveManyConversations([body.id]));
    } else if (body.op === "move" && body.l !== "3") {
      this.removeConversationOrMessageFromTrash(body.id);
    }
    if (this.isOnline && (body.op === "spam" || body.op === "move" || body.op === "trash" ||  body.op === "!spam")) {
      if (body.op === "trash") {
        const popid = body.id + "_read";
        this.databaseService.deletePendingOperation(popid).subscribe(() => {
              console.log("[ConversationRepository][processPendingOperations] cleared", popid);
              // re-enqueue ?
        });
      }
      this.conversationActionApplyToDB(body).subscribe(res => {
        console.log("[ConversationRepository][conversationAction] UI only", res);
      });
      this.mailService.conversationAction(body).subscribe(res => {
        // TODO: maybe do not wait for DB write and return resp in parallel

        response.next(res);
      }, error => {
        if (typeof error === "string" && error.includes("no such")) {
          this.deleteConversationsFromDB([body.id]);
          this.store.dispatch(new RemoveManyConversations([body.id]));
          this.conversationActionApplyToDB(body).subscribe(res => {
            response.next(res);
          });
        } else {
          console.error("[ConversationRepository][conversationAction]", error);
          response.error(error);
        }
      });
    } else {
      // skip read of pending convs
      if (body.op === "read" && body.id.includes("fake#")) {
        this.conversationActionApplyToDB(body).subscribe(res => {
          response.next(res);
        });
      } else {
        const request = {
          "url": "/api/convAction",
          "method": "post",
          "body": body
        };

        // body.id can be single id or comma separated string of ids

        if (isUndo && !this.isOnline) {
          // do not add a pending op,
          // instead remove all the prev 'move' or 'trash' ops

          this.databaseService.deletePendingOperations([
            this.databaseService.getPendingOperationKey(body.id, "trash"),
            this.databaseService.getPendingOperationKey(body.id, "spam")
          ]).subscribe(res => {
            this.conversationActionApplyToDB(body).subscribe(res => {
              response.next(res);
            });
          });

        } else {
          if (!this.isOnline) {
            if (body.op === "flag") {
              if (this.databaseService.getPendingOperationKey(body.id, "!flag")) {
                this.databaseService.deletePendingOperations([
                  this.databaseService.getPendingOperationKey(body.id, "!flag")
                ]);
              }
            } else if (body.op === "!flag") {
              if (this.databaseService.getPendingOperationKey(body.id, "flag")) {
                this.databaseService.deletePendingOperations([
                  this.databaseService.getPendingOperationKey(body.id, "flag")
                ]);
              }
            }
          }

          this.databaseService.addPendingOperation(body.id, body.op, request).subscribe(res => {
            if (this.isOnline) {
              this.mailBroadcaster.broadcast(BroadcastKeys.PROCESS_PENDING_OPERATIONS);
            }
            this.conversationActionApplyToDB(body).subscribe(res => {
              response.next(res);
            });
          });
        }
      }
    }

    return response.asObservable().pipe(take(1));
  }

  conversationActionApplyToDB(body): Observable<any> {
    console.log("[ConversationRepository][conversationActionApplyToDB]", body);

    const response = new Subject<any>();

    // body.id can be single id or comma separated string of ids
    const ids = body.id.split(",");

    if (body.op === "read" || body.op === "!read") {
      this.getConversationsByIds(ids).pipe(take(1)).subscribe(convs => {
        const convsToUpdate = convs.filter(v => !!v).map(c => {
          return body.op === "read" ?
              this.updateReadConversationFields(c) :
              this.updateUnreadConversationFields(c);
        });

        this.databaseService.updateConversations(convsToUpdate).subscribe(res => {
          response.next(true);
        });
      });

    } else if (body.op === "delete") {
      this.databaseService.deleteConversations(ids).subscribe(res => {
        response.next(true);
      });

    } else if (body.op === "update") {
      this.getConversationsByIds(ids).pipe(take(1)).subscribe(convs => {
        const convsToUpdate = [];
        convs.forEach(m => {
          const fieldsToUpdate = SharedUtils.sliceExcept(body, ["id", "op"]);
          convsToUpdate.push({...m, ...fieldsToUpdate});
        });

        this.databaseService.updateConversations(convsToUpdate).subscribe(res => {
          response.next(true);
        });
      });

    } else if (body.op === "tag" || body.op === "!tag") {
      const convs: Conversation[] = body.op === "tag" ?
                                          this.addTagToConversationsIds(ids, {name: body.tn, id: body.t, color: null}) :
                                          this.removeTagFromConversationsIds(ids, {name: body.tn, id: body.t, color: null});

      this.databaseService.updateConversations(convs).subscribe(res => {
        response.next(true);
      });
    } else if (body.op === "flag" || body.op === "!flag") {
      const isStarred = body.op === "flag";

      const convs: Conversation[] = isStarred ?
                                          this.updateFlagConversationsFields(ids) :
                                          this.updateUnFlagConversationsFields(ids);

      this.databaseService.updateConversationsAsStarred(convs, isStarred).subscribe(res => {
        response.next(true);
      });
    } else {
      let moveTo;
      if (body.op === "trash") {
        moveTo = MailConstants.SEARCH_CRITERIA.IN_TRASH;
      } else if (body.op === "move") {
        moveTo = MailUtils.getQueryByFolderId(body.l);
      } else if (body.op === "spam") {
        moveTo = MailConstants.SEARCH_CRITERIA.IN_JUNK;
      } else if (body.op === "!spam") {
        moveTo = MailConstants.SEARCH_CRITERIA.IN_INBOX;
      }

      this.databaseService.moveConversationsBetweenFolders(ids, moveTo).subscribe(res => {
        response.next(true);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  messageAction(body, isUndo?: boolean, isBulkAction?: boolean): Observable<any> {
    console.log("[ConversationRepositorymessageAction]", body);

    const response = new Subject<any>();
    if (body.op === "trash" || body.op === "move" && body.l === "3") {
      this.addConversationOrMessageToTrash(body.id);
      // ToDoReview: really delete when move to trash ?
      // it is moved to trash folder 8 lines below... so need to check
      // this.deleteMessagesFromDB([body.id]);
      this.store.dispatch(new RemoveManyMessages([body.id]));
    } else if (body.op === "move" && body.l !== "3") {
      this.removeConversationOrMessageFromTrash(body.id);
    }
    if (this.isOnline && (body.op === "spam" || body.op === "move" || body.op === "trash" ||  body.op === "!spam")) {
      console.log("[ConversationRepositorymessageAction][messageAction] this.messageActionApplyToDB ", body);
      // OMG - it takes longer to apply!
      this.messageActionApplyToDB(body).subscribe(() => {
        console.log("[ConversationRepositorymessageAction][messageAction] messageActionAppliedToDB ", body);
      });

      // this.mailService.messageAction(body).subscribe(res => {
      const request = {
        "url": "/api/msgAction",
        "method": "post",
        "body": body
      };
      console.log("[ConversationRepositorymessageAction][addPendingOp] ", request);
      this.databaseService.addPendingOperation(body.id, body.op, request).subscribe(res => {
        // TODO: maybe do not wait for DB write and return resp in parallel
        console.log("[ConversationRepositorymessageAction][addedPendingOp] ", request);
        this.mailBroadcaster.broadcast(BroadcastKeys.PROCESS_PENDING_OPERATIONS);
        response.next(res);
      }, error => {
        console.error("[ConversationRepository][conversationAction]", error);
        if (typeof error === "string" && error.includes("no such")) {
          this.deleteMessagesFromDB([body.id]);
          this.store.dispatch(new RemoveManyMessages([body.id]));
        }
        response.error(error);
      });
    } else {
      // skip read of pending messages
      if (body.op === "read" && body.id.includes("fake#")) {
        this.messageActionApplyToDB(body).subscribe(res => {
          response.next(res);
        });
      } else {
        const request = {
          "url": "/api/msgAction",
          "method": "post",
          "body": body
        };

        // body.id can be single id or comma separated string of ids

        if (isUndo && !this.isOnline) {
          // do not add a pending op,
          // instead remove all the prev 'move' or 'trash' ops

          this.databaseService.deletePendingOperations([
            this.databaseService.getPendingOperationKey(body.id, "trash"),
            this.databaseService.getPendingOperationKey(body.id, "spam")
          ]).subscribe(res => {
            this.messageActionApplyToDB(body).subscribe(res => {
              response.next(res);
            });
          });
        } else {
          if (!this.isOnline) {
            if (body.op === "flag") {
              if (this.databaseService.getPendingOperationKey(body.id, "!flag")) {
                this.databaseService.deletePendingOperations([
                  this.databaseService.getPendingOperationKey(body.id, "!flag")
                ]);
              }
            } else if (body.op === "!flag") {
              if (this.databaseService.getPendingOperationKey(body.id, "flag")) {
                this.databaseService.deletePendingOperations([
                  this.databaseService.getPendingOperationKey(body.id, "flag")
                ]);
              }
            }
          }
          console.log("[ConversationRepository][messageAction][addPendingOperation]", body);
          this.databaseService.addPendingOperation(body.id, body.op, request).subscribe(res => {
            if (this.isOnline && !isBulkAction) {
              this.mailBroadcaster.broadcast(BroadcastKeys.PROCESS_PENDING_OPERATIONS);
            }
            this.messageActionApplyToDB(body).subscribe(res => {
              response.next(res);
            });
            this.databaseService.getAllPendingOperations().subscribe(v => {
              console.log("[ConversationRepository][messageAction][getAllPendingOperations]", v);
            });
          });
        }
      }
    }

    return response.asObservable().pipe(take(1));
  }

  messageActionApplyToDB(body): Observable<any> {
    console.log("[ConversationRepository][messageActionApplyToDB]", body);

    const response = new Subject<any>();

    const ids = body.id.split(","); // it can be single id or comma separated string ids

    if (body.op === "read" || body.op === "!read") {
      this.getMessagesByIds(ids).pipe(take(1)).subscribe(msgs => {
        const messagesToUpdate = msgs.map(m => {
          return body.op === "read" ?
              this.updateReadMessageFields(m) :
              this.updateUnreadMessageFields(m);
        });

        this.databaseService.updateMessages(messagesToUpdate).subscribe(res => {
          response.next(true);
        });
      });

    } else if (body.op === "delete") {
      this.databaseService.deleteMessages(ids).subscribe(res => {
        response.next(true);
      });

    } else if (body.op === "update") {
      const ids = body.id.split(","); // it can be single id or comma separated string ids
      this.getMessagesByIds(ids).pipe(take(1)).subscribe(msgs => {
        const messagesToUpdate = [];
        msgs.forEach(m => {
          const fieldsToUpdate = SharedUtils.sliceExcept(body, ["id", "op"]);
          messagesToUpdate.push({...m, ...fieldsToUpdate});
        });
        console.log("[ConversationRepository][messageActionApplyToDB][updateMessages]", messagesToUpdate);
        this.databaseService.updateMessages(messagesToUpdate).subscribe(res => {
          response.next(true);
        });
      });

    } else if (body.op === "tag" || body.op === "!tag") {
      const msgs: Message[] = body.op === "tag" ?
                                          this.addTagToMessagesIds(ids, {name: body.tn, id: body.t, color: null}) :
                                          this.removeTagFromMessagesIds(ids, {name: body.tn, id: body.t, color: null});

      this.databaseService.updateMessages(msgs).subscribe(res => {
        response.next(true);
      });
    } else if (body.op === "flag" || body.op === "!flag") {
      const isStarred = body.op === "flag";

      const msgs: Message[] = isStarred ?
                                          this.updateFlagMessages(ids) :
                                          this.updateUnFlagMessages(ids);

      this.databaseService.updateMessagesAsStarred(msgs, isStarred).subscribe(res => {
        response.next(true);
      });
    } else {

      let moveTo;
      if (body.op === "trash") {
        moveTo = MailConstants.SEARCH_CRITERIA.IN_TRASH;
      } else if (body.op === "move") {
        moveTo = MailUtils.getQueryByFolderId(body.l);
      } else if (body.op === "spam") {
        moveTo = MailConstants.SEARCH_CRITERIA.IN_JUNK;
      } else if (body.op === "!spam") {
        moveTo = MailConstants.SEARCH_CRITERIA.IN_INBOX;
      }

      this.databaseService.moveMessagesBetweenFolders(ids, moveTo).subscribe(res => {
        // console.log("[ConversationRepository][moveMessagesBetweenFolders2]", res);
        response.next(true);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  deleteMessage(body): Observable<any> {
    console.log("[ConversationRepository][deleteMessage]", body);
    return this.messageAction(body);
  }

  deleteConversation(body): Observable<any> {
    console.log("[ConversationRepository][deleteConversation]", body);
    return this.conversationAction(body);
  }


  retrieveMessagesByIdFromServer(id: string, delayStore?: boolean): Observable<Message[]> {
    console.log(`[ConversationRepository][retrieveMessagesByIdFromServer]`, id);

    const response = new Subject<Message[]>();

    if (this.isOnline) {
      if (window.appInBackground && CommonUtils.isOnAndroid()) {
        this.mailService.backgroundRetrieveMessagesById(id, this.currentQuery).subscribe(messages => {
          console.log(`[ConversationRepository][retrieveMessagesByIdFromServer] messages`, messages);
          if (!!delayStore && !!this.configService.worker) {
            this.configService.worker.postMessage({ type: "createOrUpdateMessages", id: new Date().getTime(), args: messages });
            response.next(messages);
          } else {
            this.addMessagesToDB(messages).subscribe(() => {
              console.log(`[ConversationRepository]addMessagesToDB[retrieveMessagesByIdFromServer] messages`, messages);
              response.next(messages);
            });
          }
        }, error => {
          console.error("[ConversationRepository][retrieveMessagesById] background error ", error);
          console.error("[ConversationRepository][retrieveMessagesById] background ", error, navigator.onLine);
          if (!navigator.onLine) {
            this.retrieveMessagesById(id).subscribe(fallbackmsg => {
              if (!!fallbackmsg && !!fallbackmsg[0]?.mp) {
                response.next(fallbackmsg);
              } else {
                response.next([]);
              }
            });
          }
          response.next([]);
        });
      } else {

        this.mailService.retrieveMessagesById(id, this.currentQuery).subscribe(messages => {
          console.log(`[ConversationRepository][retrieveMessagesByIdFromServer] messages`, messages);
          if (!!delayStore && !!this.configService.worker) {
            this.configService.worker.postMessage({ type: "createOrUpdateMessages", id: new Date().getTime(), args: messages });
            response.next(messages);
          } else {
            // save to DB
            // TODO: maybe do not wait for DB write and return resp in parallel
            this.addMessagesToDB(messages).subscribe(() => {
              console.log(`[ConversationRepository]addMessagesToDB[retrieveMessagesByIdFromServer] messages`, messages);
              response.next(messages);
            });
          }
        }, error => {
          console.error("[ConversationRepository][retrieveMessagesByIdFromServer] error ", error);
          console.error("[ConversationRepository][retrieveMessagesByIdFromServer]  ", error, navigator.onLine);
          if (!navigator.onLine) {
            this.retrieveMessagesById(id).subscribe(fallbackmsg => {
              if (!!fallbackmsg && !!fallbackmsg[0]?.mp) {
                response.next(fallbackmsg);
              } else {
                response.next([]);
              }
            });
          }
          response.next([]);
        });
      }
    } else {
      // get from DB
      this.databaseService.getMessageById(id).subscribe(message => {
        response.next(message ? [message] : []);
      }, error => {
        console.error(`[ConversationRepository][retrieveMessagesByIdFromServer][getMessageById]`, error);
        response.next([]);
      });
    }

    return response.asObservable().pipe(take(1));
  }



  retrieveMessagesById(id: string, isDraftMail?: boolean): Observable<Message[]> {
    console.log(`[ConversationRepository][retrieveMessagesById]`, id, isDraftMail, this.isOnline, navigator.onLine);
    let nts = new Date().getTime();

    const response = new Subject<Message[]>();

    if (this.isOnline) {
      if ((environment.isCordova && (nts - this.lastTimeResume) < 25000) || isDraftMail) { // should load latest draft mail content
        // fast path - online... skip db check, load from serverm add to db in background
        this.mailService.retrieveMessagesById(id, this.currentQuery).subscribe(messages => {
          console.log(`[ConversationRepository][retrieveMessagesById] fast after resume, messages`, messages);
          response.next(messages);
          this.addMessagesToDB(messages).subscribe(() => {
            console.log(`[ConversationRepository]addMessagesToDB[retrieveMessagesById] messages`, messages);
          });
        }, error => {
          console.error("[ConversationRepository][retrieveMessagesById] error ", error, navigator.onLine);
          if (error.indexOf("Unknown Error") > -1) {
            console.error("[ConversationRepository][retrieveMessagesById] attempting db ", id);
            this.databaseService.getMessageById(id).subscribe(dbmessage => {
              console.log("[ConversationRepository][retrieveMessagesById] dbMessage: ", dbmessage);
              if (!!dbmessage && !!dbmessage.mp) {
                response.next([dbmessage]);
              } else {
                response.next([]);
              }
            });
          } else {
            response.error(error);
          }
        });
      } else {
        // standard online case
        this.databaseService.getMessageById(id).subscribe(dbmessage => {

          /* checkForAttachmentIssue
          let fetchAttachments = false;
          if (!!dbmessage.f) {
            console.log("[ConversationRepository][retrieveMessagesById] isAttachmentExpand, flags: ", dbmessage.f);
            if (dbmessage.f.indexOf("a") > -1) {
              fetchAttachments = true;
            }
          }
          if (!!dbmessage && !!dbmessage.mp && !fetchAttachments) {
          */
          console.log("[ConversationRepository][retrieveMessagesById] dbMessage: ", dbmessage);
          const dbMessageKeys = !!dbmessage ? Object.keys(dbmessage) : [];
          if (!!dbmessage && !!dbmessage.mp && !!dbmessage.su && !!dbmessage.e && (!dbMessageKeys.includes("inv"))) {
            response.next([dbmessage]);
          } else {
            this.mailService.retrieveMessagesById(id, this.currentQuery).subscribe(messages => {
              console.log(`[ConversationRepository][retrieveMessagesById] messages`, messages);
              response.next(messages);
              // save to DB
              // TODO: maybe do not wait for DB write and return resp in parallel
              this.addMessagesToDB(messages).subscribe(() => {
                console.log(`[ConversationRepository]addMessagesToDB[retrieveMessagesById] messages`, messages);
                setTimeout(() => {
                  this.mailBroadcaster.broadcast("REFRESH_ONE_PAGE");
                }, 300);
              });
            }, error => {
              console.error("[ConversationRepository][retrieveMessagesById] error ", error, navigator.onLine);
              if (error.indexOf("Unknown Error") > -1) {
                response.next([]);
              } else {
                response.error(error);
              }
            });
          }
        });
      }
    } else {
      // get from DB
      this.databaseService.getMessageById(id).subscribe(message => {
        response.next(message ? [message] : []);
      }, error => {
        console.error(`[ConversationRepository][retrieveMessagesById][getMessageById]`, error);
        response.next([]);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  getConversationMessages(query: SearchConvRequest, conversation?: Conversation): Observable<Message[]> {
    console.log("[ConversationRepository][getConversationMessages]");

    const response = new Subject<Message[]>();

    if (this.isOnline) {
      this.databaseService.getMessagesByConversationId(query.cid).subscribe(dbmessages => {
        console.log("[ConversationRepository][getConversationMessages] fromDB1 ", dbmessages);
        let isInvConversation = false;
        dbmessages.forEach(m => {
          const dbMessageKeys = Object.keys(m);
          if (dbMessageKeys.includes("inv")) {
            isInvConversation = true;
          }
        });
        if ((dbmessages.length > 0) && !!dbmessages[0].mp && !isInvConversation) {
          console.log("[ConversationRepository][getConversationMessages] fromDB2 ", dbmessages);
          // first dbmessage has mp, other conv messages are using retrieveMessagesById anyways
          this.addMessagesToStore(dbmessages);
          this.updateConversationMessages(dbmessages);
          response.next(dbmessages);
        } // first load from DB then getting new from server

        if (isInvConversation || dbmessages.length === 0 || (conversation && conversation.m && conversation.m.length !== dbmessages.length)
          || dbmessages.length > 0 && (!dbmessages[0].e || !dbmessages[0].mp)) {
          this.mailService.getConversationMessages(query, this.currentQuery).subscribe(messages => {
            response.next(messages);
            console.log("[ConversationRepository][getConversationMessages] fromServer messages", messages);
            this.updateConversationMessages(messages);
            this.addMessagesToStore(messages);

            // save to DB
            // TODO: maybe do not wait for DB write and return resp in parallel
            this.addMessagesToDB(messages).subscribe(() => {
            });
          }, error => {
            console.error("[ConversationRepository][getConversationMessages]", error);
            response.error(error);
          });
        }

      }, error => {
        console.error(`[ConversationRepository][getMessageById][getMessagesByConversationId] DB `, error);
        response.next([]);
      });
    } else {
      this.databaseService.getMessagesByConversationId(query.cid).subscribe(messages => {
        console.log("[ConversationRepository][getConversationMessagesDB] messages", messages);
        this.addMessagesToStore(messages);

        response.next(messages);
      }, error => {
        console.error(`[ConversationRepository][getMessageById][getMessagesByConversationId]`, error);
        response.next([]);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  getMessagesByIdFromDB(mid: string) {
    const response = new Subject<any>();

    this.databaseService.getMessageById(mid).subscribe(message => {
      response.next(message);
    }, error => {
      console.error(`[ConversationRepository][getMessagesByIdFromDB]`, error);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesByCidFromDB(cid: string) {
    const response = new Subject<any>();

    this.databaseService.getMessagesByConversationId(cid).subscribe(messages => {
      response.next(messages);
    }, error => {
      console.error(`[ConversationRepository][getMessagesByCidFromDB]`, error);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }

  getMessageCidFromDB(msg: any) {
    const response = new BehaviorSubject<any>("null");

    console.log("[ConversationRepository][getMessageCidFromDB] msg: ", msg);
    if (msg?.cid) {
      response.next(msg.cid);
    } else {
      if (!!msg && !!msg.id) {
        this.databaseService.getMessageById(msg.id).subscribe(dbMessage => {
          response.next(dbMessage ? dbMessage.cid : null);
        }, error => {
          console.error(`[ConversationRepository][getMessageCidFromDB]`, error);
          response.next(null);
        });
      } else {
        response.next(null);
      }
    }

    return response.asObservable().pipe(filter(cid => cid !== "null"), take(1));
  }

  addMessagesToDB(messages: Message[], query?: any) {
    const response = new Subject<any>();
    // console.log("[conversationrepo addMessagesToDB ", messages);
    this.databaseService.addMessages(messages, query).subscribe(() => {
      response.next(true);
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  updateMessagesInDB(messages: Message[]) {
    const response = new Subject<any>();

    this.databaseService.updateMessages(messages).subscribe(() => {
      response.next(true);
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  deleteMessagesFromDB(messagesIds: string[]) {
    const response = new Subject<any>();

    console.log("[conversationRepo] deleteMessagesFromDB: ", messagesIds, messagesIds.length);
    const msgIds = messagesIds.filter(id => !id.startsWith("-"));
    console.log("[conversationRepo] deleteMessagesFromDB msgIds: ", msgIds, msgIds.length);
    this.databaseService.deleteMessages(messagesIds).subscribe(() => {
      response.next(true);
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  replaceMessageInDB(prevMessageId: string, newMessage: Message) {
    console.log(`[ConversationRepository][replaceMessageInDB] addMessage`, prevMessageId, newMessage);

    const response = new Subject<any>();

    this.databaseService.deleteMessage(prevMessageId).subscribe(res => {
      this.databaseService.addMessages([newMessage]).subscribe(res => {
        console.log(`[ConversationRepository][replaceMessageInDB]`, "Ok");
        response.next(true);
      }, err => {
        response.error(err);
      });
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationByIdFromDB(id: string): Observable<Conversation> {
    console.log(`[ConversationRepository][getConversationByIdFromDB]`, id);

    const response = new Subject<Conversation>();

    // get from DB
    this.databaseService.getConversationById(id).subscribe(conv => {
      console.log(`[ConversationRepository][getConversationByIdFromDB]`, id, conv);
      if (!!conv) {
        response.next(conv);
      } else {
        console.log(`[ConversationRepository][getConversationByIdFromDB] no conv!`, id);
        response.error({message: "no conv", id: id});
      }
    }, error => {
      console.error(`[ConversationRepository][getConversationByIdFromDB]`, error);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }

  addConversationsToDB(convs: Conversation[]) {
    const response = new Subject<any>();

    this.databaseService.addConversations(convs).subscribe(res => {
      response.next(true);
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  updateConversationsInDB(conversations: Conversation[]) {
    const response = new Subject<any>();

    this.databaseService.updateConversations(conversations).subscribe(() => {
      response.next(true);
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  deleteConversationsFromDB(convsIds: string[], keepMessages?: boolean) {
    const response = new Subject<any>();

    this.databaseService.deleteConversations(convsIds, keepMessages).subscribe(() => {
      response.next(true);
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  addConversationOrMessageToTrash(id: string) {
    if (!this.inTrashIds.includes(id)) {
      this.inTrashIds.push(id);
    }
  }

  removeConversationOrMessageFromTrash(id: string) {
    this.inTrashIds = this.inTrashIds.filter(v => v !== id);
  }

  replaceConversationInDB(prevConvId: string, newConv: Conversation) {
    console.log(`[ConversationRepository][replaceConversationInDB]`, prevConvId, newConv);

    const response = new Subject<any>();

    this.databaseService.deleteConversations([prevConvId]).subscribe(() => {
      this.databaseService.addConversations([newConv]).subscribe(() => {
        console.log(`[ConversationRepository][replaceConversationInDB]`, "Ok");
        response.next(true);
      }, err => {
        response.error(err);
      });
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  saveMailAsDraft(saveSendMessage: SaveSendMessage, convId?: string, isSendAndSeal?: boolean): Observable<any> {
    const attachmentKeys = (!!saveSendMessage.attach) ? Object.keys(saveSendMessage.attach) : [];
    if (!this.configService.backgroundSend) {
      isSendAndSeal = true;
    }
    console.log(`[ConversationRepository][saveMailAsDraft1]`, saveSendMessage, convId, attachmentKeys, saveSendMessage.origid, isSendAndSeal);
    let hasOnlyFidOrNoAttachment = true;
    if ((attachmentKeys.length > 1) || (attachmentKeys[0] !== "fid")) {
      hasOnlyFidOrNoAttachment = false;
    }
    // console.log(`[ConversationRepository][saveMailAsDraft1] hasOnlyFid `, hasOnlyFidOrNoAttachment);
    const response = new Subject<any>();
    if (isSendAndSeal && !!saveSendMessage.id && this.isFakeId(saveSendMessage.id)) {
      delete saveSendMessage.id;
    }
    if ((this.isOnline && !this.databaseService.isUsingIndexedDb()) || isSendAndSeal) {
    // if ((this.isOnline && !CommonUtils.isOfflineModeSupported()) || (this.isOnline && !hasOnlyFidOrNoAttachment)) {
      // console.log(`[ConversationRepository][saveMailAsDraft1] using old online flow `, hasOnlyFidOrNoAttachment);
      if (saveSendMessage.attach && saveSendMessage.attach.fid) {
        delete saveSendMessage.attach.fid;
      }
      this.mailService.saveMailAsDraft(saveSendMessage).subscribe(msgRes => {
        // TODO: maybe do not wait for DB write and return resp in parallel
        console.log("[conversationrepo][savemailasdraft] msgRes: ", msgRes);
        this.saveMailAsDraftApplyToDB(msgRes.m[0]).subscribe(() => {
          response.next(msgRes);
        });

      }, error => {
        response.error(error);
      });
    } else {
      let requestBody = { ...saveSendMessage };
      if (this.isFakeId(requestBody.id)) {
        delete requestBody.id;
      }
      if (this.isFakeId(requestBody.did)) {
        delete requestBody.did;
      }
      const request = {
        "url": "/api/saveDraft",
        "method": "post",
        "body":  requestBody
      };

      // fake id
      if (!saveSendMessage.id) {
        saveSendMessage.id = this.createFakeId(saveSendMessage.content);
      }

      this.databaseService.addPendingOperation(saveSendMessage.id, "saveDraft", request).subscribe(res => {
        const preparedMessage = MailUtils.createLocalMessageFromSaveSendObject(saveSendMessage,
                                              MailConstants.SEARCH_CRITERIA.IN_DRAFTS, convId);
        if (this.isOnline) {
          this.mailBroadcaster.broadcast(BroadcastKeys.PROCESS_PENDING_OPERATIONS);
        }
        console.log("conversationrepo saveMailAsDraft offlineDraft: ", preparedMessage);
        this.saveMailAsDraftApplyToDB(preparedMessage).subscribe(() => {
          response.next(true);
        });
     });
    }

    return response.asObservable().pipe(take(1));
  }

  private saveMailAsDraftApplyToDB(msg): Observable<any> {
    const response = new Subject<any>();

    msg.l = "6"; // draft
    this.addMessagesToDB([msg]).subscribe(() => {
      response.next(true);
    }, err => {
      // parallel message adding issue,
      // e.g. when it's added via save draft form component and via noop - at the same time
      // so we simply ignore it and return a success responce.
      // TODO: need to implement DB transactions;
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  discardDraft(id: string, sync = false): Observable<any> {
    const response = new Subject<any>();

    console.log("[ConversationRepository][discardDraft]", id);

    if (this.isOnline) {
      if (!id.startsWith("fake#")) {
        const body = { id, op: "delete" };
        if (!this.databaseService.isUsingIndexedDb() || sync) {
          this.mailService.deleteMessage(body).subscribe(res => {
            this.databaseService.deleteMessage(id).subscribe((res) => {
              response.next(true);
            }, err => {
              response.error(err);
            });
          }, err => {
            response.error(err);
          });
        } else {
          const request = {
            "url": "/api/msgAction",
            "method": "post",
            "body": body
          };
          this.databaseService.addPendingOperation(id, "messageAction", request).subscribe(() => {
            this.databaseService.deleteMessage(id).subscribe((res) => {
              response.next(true);
            }, err => {
              response.error(err);
            });
          }, err => {
            response.next(err);
          });
        }
      } else {
        this.databaseService.deleteMessage(id).subscribe((res) => {
          response.next(true);
        }, err => {
          response.error(err);
        });
      }
    } else {
      const key = this.databaseService.getPendingOperationKey(id, "saveDraft");
      this.databaseService.deletePendingOperation(key).subscribe(res => {
        this.databaseService.deleteMessage(id).subscribe((res) => {
          response.next(true);
        }, err => {
          response.error(err);
        });
      }, err => {
        response.error(err);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  sendEmail(saveSendMessage: SaveSendMessage, convId?: string, isSendAndSeal?: boolean): Observable<any> {
    const response = new Subject<any>();

    console.log("[ConversationRepository][sendEmail]", saveSendMessage, convId);
    if (!this.configService.backgroundSend) {
      isSendAndSeal = true;
    }
    if (this.isOnline && ((!this.databaseService.isUsingIndexedDb()) || isSendAndSeal) ) {
      if (saveSendMessage.attach && saveSendMessage.attach.fid) {
        delete saveSendMessage.attach.fid;
      }
      saveSendMessage.did = saveSendMessage.id;
      this.mailService.sendEmail(saveSendMessage).subscribe(resMsg => {

        console.log("[ConversationRepository][sendEmail] resMsg", resMsg);

        // TODO: maybe do not wait for DB write and return resp in parallel
        //

        // when send an email after save a draft, the msg id will be incremented, e.g. 6318 -> 6319
        const prevMsgId = saveSendMessage.id;
        if (resMsg.m[0].id) {
          saveSendMessage.id = resMsg.m[0].id;
          const preparedMessage: Message = MailUtils.createLocalMessageFromSaveSendObject(saveSendMessage,
                                                        MailConstants.SEARCH_CRITERIA.IN_SENT, convId);
          this.sendMailApplyToDB(prevMsgId, preparedMessage, null, convId).subscribe(resOk => {
            response.next(resMsg);
          });
        } else {
          response.next("");
        }
      }, error => {
        response.error(error);
      });
    } else {
      let requestBody = { ...saveSendMessage };
      if (this.isFakeId(requestBody.id)) {
       delete requestBody.id;
      }
      if (this.isFakeId(requestBody.did)) {
       delete requestBody.did;
      }
      const request = {
       "url": "/api/sendEmail",
       "method": "post",
       "body": requestBody
      };

      // fake id
      if (!saveSendMessage.id) {
       saveSendMessage.id = this.createFakeId(saveSendMessage.content);
      }

      this.addPendingOperation(saveSendMessage.id, "sendEmail", request).subscribe(res => {
        const preparedMessage: Message = MailUtils.createLocalMessageFromSaveSendObject(saveSendMessage,
                                                          MailConstants.SEARCH_CRITERIA.IN_SENT, convId);
        const convToCreate: Conversation = convId ? null : MailUtils.createLocalConvFromMessage(preparedMessage);
        if (this.isOnline) {
          this.mailBroadcaster.broadcast(BroadcastKeys.PROCESS_PENDING_OPERATIONS);
        }
        this.sendMailApplyToDB(saveSendMessage.id, preparedMessage, convToCreate, convId).subscribe(resOk => {
          response.next(true);
        });
      });

    }


    // save emails for autosuggestion next time
    const toEmailsUsers = [];
    saveSendMessage.emailInfo.forEach(ei => {
      const data: any = {
        email: ei.a,
        name: ei.p
      };
      const user = MailUtils.mapAutocompleteUser(data, true);
      toEmailsUsers.push(user);
    });
    // console.log("[ConversationRepository][sendEmail] toEmailsUsers", toEmailsUsers);
    this.databaseService.addUsers(toEmailsUsers);

    return response.asObservable().pipe(take(1));
  }

  private sendMailApplyToDB(prevMsgId: string, msg: Message, convToCreate: Conversation, convIdToAddMessage?: string): Observable<any> {
    const response = new Subject<any>();

    console.log("[ConversationRepository][sendMailApplyToDB]", prevMsgId, msg.id, msg);

    if (prevMsgId) {
      // when send an email after save a draft, the msg id will be incremented, e.g. 6318 -> 6319
      // remove old message
      this.databaseService.deleteMessage(prevMsgId).subscribe(res => {
        this._sendMailApplyToDB(msg, convToCreate, convIdToAddMessage).subscribe(r => {
          response.next(r);
        });
      });
    } else {
      this._sendMailApplyToDB(msg, convToCreate, convIdToAddMessage).subscribe(r => {
        response.next(r);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  private _sendMailApplyToDB(msg: Message, convToCreate: Conversation, convIdToAddMessage?: string): Observable<any> {
    const response = new Subject<any>();

    msg.l = "5"; // sent
    this.addMessagesToDB([msg]).subscribe(res => {
      if (convToCreate) {
        this.addConversationsToDB([convToCreate]).subscribe(res => {
          response.next(true);
        });
      } else if (convIdToAddMessage) {
        this.databaseService.addMessageToConversation(convIdToAddMessage,
                  MailUtils.convertSentMessageToShortMessage(msg)).subscribe(res => {
          response.next(true);
        });
      } else {
        response.next(true);
      }
    });

    return response.asObservable().pipe(take(1));
  }

  private createFakeId(data: string) {
    return `fake#${MailUtils.md5(data)}`;
  }

  private isFakeId(id: string) {
    return id && id.startsWith("fake#");
  }


  // Attachments
  //

  removeMailAttachment(messageId: string, attachmentPart: string): Observable<Message> {
    const response = new Subject<any>();

    this.mailService.removeMailAttachment(messageId, attachmentPart).pipe(take(1)).subscribe(msg => {
      response.next(msg);
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }


  // Autocomplete
  //

  getAutoCompleteList(inputValue: any): Observable<any> {
    // console.log("[ConversationRepository][getAutoCompleteList] input: ", inputValue, this.isOnline);
    const response = new Subject<any>();

    if (this.isOnline) {
      this.databaseService.getUsers(inputValue).subscribe(res => {
        if (res.length > 0) {
          console.log("[ConversationRepository][getAutoCompleteList] DBusers ", inputValue, res);
          response.next(res);
        }
      });
      this.mailService.getAutoCompleteList(inputValue).subscribe(res => {
          // console.log("[ConversationRepository][getAutoCompleteList] res", res);

          const parsedUsers = this.parseAutocompleteListResponse(res);
          this.databaseService.addUsers(parsedUsers);

          // console.log("[ConversationRepository][getAutoCompleteList] parsedUsers", parsedUsers);
          let filteredUsers = parsedUsers.filter(u => ((u.email.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) || (u.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)));
          console.log("[ConversationRepository][getAutoCompleteList] filtered parsedUsers", inputValue, filteredUsers);

          response.next(filteredUsers);
          response.complete();

        }, error => {
          console.error("[ConversationRepository][getAutoCompleteList]", error);
          response.error(error);
          response.complete();
        });

    // retrieve from DB
    } else {
      // TODO: possible also search in CONTACTS_STORE
      //
      return this.databaseService.getUsers(inputValue);
    }

    return response.asObservable();
  }

  getAutoCompleteGalList(inputValue: string): Observable<any> {
    const response = new Subject<any>();

    if (this.isOnline) {
      this.mailService.getAutoCompleteGalList(inputValue).subscribe(res => {
        console.log("[ConversationRepository][getAutoCompleteGalList] res", res);

        const parsedUsers = this.parseAutocompleteGalListResponse(res);
        this.databaseService.addUsers(parsedUsers);

        response.next(parsedUsers);
      }, error => {
        console.error("[ConversationRepository][getAutoCompleteGalList]", error);
        response.error(error);
      });
    // retrieve from DB
    } else {
      return this.databaseService.getUsers(inputValue);
    }

    return response.asObservable().pipe(take(1));
  }

  private parseAutocompleteListResponse(res) {
    if (!Array.isArray(res)) {
      res = [res];
    }

    const searchedUsers = [];

    res.forEach(item => {
      const user = MailUtils.mapAutocompleteUser(item);
      searchedUsers.push(user);
    });

    return searchedUsers;
  }

  private parseAutocompleteGalListResponse(res) {
    if (!Array.isArray(res)) {
      res = [res];
    }

    const searchedUsers = [];

    console.log("[ConversationRepository][parseAutocompleteGalListResponse] res", res);
    // item._attrs.zimbraCalResType

    res.forEach(item => {
      const data: any = {
        email: item._attrs.email,
        name: item._attrs.fullName
      };
      const user = MailUtils.mapAutocompleteUser(data);
      searchedUsers.push(user);
    });

    return searchedUsers;
  }

  //
  //

  backgroundSyncRequest(syncToken?: string, msgCutoff?: number) {

    let body = {
      "typed": 1,
       "l": "1"
    };
    if (!!syncToken) {
      body["token"] = syncToken;
    } else {
      let msgCutoff = Math.floor(Date.now() / 1000) - 86400;
      body["msgCutoff"] = msgCutoff;
    }

    return this.mailService.backgroundSyncRequest(body);
  }


  syncRequest(syncToken: string, msgCutoff?: number) {
    const body = {
      "token": syncToken,
      "typed": 1,
       "l": "1",
    };

    return this.mailService.syncRequest(body);
  }

  syncRequestResync(msgCutoff?: number) {
    let body = {
      "typed": 1,
      "l": "1"
    };

    if (!!msgCutoff) {
      body["msgCutoff"] = msgCutoff;
    }

    return this.mailService.syncRequest(body);
  }


  getSearchMailFromSolr(from: string, to: string, folderId: string, offset: number, limit: number, searchType: string, tag: string, searchText: string,
    sorts: any, attachment, beforeDate: string, afterDate: string, exacDate: string, showMails: string, filters: any, extraFilters?: any): Observable<any> {
      let allMailFolders: MailFolder [] = [];
      this.store.select(getSystemFolders).pipe(take(1)).subscribe(res => {
        allMailFolders = res;
      });
      this.setConversationIsLoading(true);
      const response = new Subject<any>();
      let totalSearchMails: number = 0;
      let type = localStorage.getItem("currentView");
      if (type === "conversation") {
        this.getCurrentConversations().pipe(filter(c => !!c), take(1)).subscribe(conversations => {
          totalSearchMails = conversations.length;
        });
      } else if (type === "message") {
        this.getCurrentMessages().pipe(filter(c => !!c), take(1)).subscribe(conversations => {
          totalSearchMails = conversations.length;
        });
      }

      this.mailService.mailSearch(searchText, searchType, offset, limit, sorts, attachment, to, from, folderId, tag, beforeDate, afterDate, exacDate, showMails, filters, extraFilters).pipe(take(1)).subscribe(res => {
        let docs = [];
        let numFound = 0;
        let start = 0;
        if (res.response) {
          console.log("[getSearchMailFromSolr][response]:", res.response);
          res.response.docs.map(val => {
            const item = this.mapSearchItem(val);
            docs.push(item);
          });
          numFound = res.response.numFound;
          start = offset;
        }
        const data = { docs: docs, numFound: numFound, start: start } as SearchResponse;
        this.configService.totalSolrCount = data.numFound;
        if (data.numFound === 0) {
          this.mailBroadcaster.broadcast(MailConstants.SET_SOLR_COUNT);
        }
        if (offset >= numFound) {
          this.store.dispatch(new SetHasMoreAction(false));
        } else {
          this.store.dispatch(new SetHasMoreAction(true));
        }
        const isSearchInTrash = this.configService.prefs.zimbraPrefIncludeTrashInSearch;
        let type = localStorage.getItem("currentView");
        this.store.select(getViewBy).pipe(take(1)).subscribe(value => {
          type = value;
        });
        console.log("[getSearchMailFromSolr][docs]:", data.docs);
        if (data.docs && data.docs.length > 0) {
          if (type === "message") {
            let messages: Message[] = [];
            messages = data.docs.filter(d => !!d.id).map(docItem => {
              return MailUtils.mapSolrEmailToMessage(docItem);
            });
            console.log("[getSearchMailFromSolr][messages]: ", messages);
            this.setConversationIsLoading(false);
            if (messages.length > 0) {
              this.addMessagesToStore(messages);
              response.next(messages);
            }
          } else if (type === "conversation") {
            let conversation: Conversation[] = [];
            conversation = data.docs.filter(d => !!d.id).map(docItem => {
              return MailUtils.mapSolrEmailToConversation(docItem);
            });
            console.log("[getSearchMailFromSolr][conversation]: ", conversation);
            this.setConversationIsLoading(false);
            if (conversation.length > 0) {
              this.setConversationLoadedSuccess(conversation);
              response.next(conversation);
            }
          }
        } else {
          this.setConversationIsLoading(false);
          response.next([]);
        }
      }, error => {
        console.log("[Error][getSearchMailFromSolr]:", error);
        this.toastService.showPlainMessage(error);
        response.error(error);
      });
      return response.asObservable().pipe(take(1));
  }

  private mapSearchItem(doc) {
    const content = doc.content_txt ? doc.content_txt[0] : "";
    const raw = doc.raw_txt ? doc.raw_txt[0] : "";
    let parsedContent = content;
    const rawTxt = raw.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
    if (rawTxt !== "") {
        parsedContent = rawTxt;
    }
    const shortContent = parsedContent;
    let id = doc.id;
    let objectId = doc.id;
    if (doc.type_s === "mail") {
        id = doc.mail_id_i;
        objectId = objectId.replace(`.${doc.mail_id_i}`, "");
    }
    return {
      id: id,
      subject: doc.title_s ? doc.title_s.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'") : "",
      rawTxt: content.replace(/\\n/g, "<br />"),
      createdDt: doc.created_dt,
      from: doc.from_s,
      to: doc.to_ss,
      type: doc.type_s,
      version: doc._version_,
      unread: doc.mail_unread_b,
      flags: doc.mail_flags_s,
      owner: doc.owner_s,
      folderId: doc.mail_folder_id_i,
      shareMailId: !!doc.mail_id_s ? doc.mail_id_s : null,
      htmlTextContent: rawTxt
    } as SearchItem;
  }

  setSearchToStore(res: SearchResponse): Observable<Message[] | Conversation[]> {
    const response = new Subject<Message[] | Conversation[]>();
    let type = localStorage.getItem("currentView");
    this.store.select(getViewBy).pipe(take(1)).subscribe(value => {
      type = value;
    });
    if (res.docs && res.docs.length > 0) {
      if (type === "message") {
        let messages: Message[] = [];
        messages = res.docs.map(docItem => {
          return MailUtils.mapSolrEmailToMessage(docItem);
        });
        this.setConversationIsLoading(false);
        if (messages.length > 0) {
          this.addMessagesToStore(messages);
          response.next(messages);
        }
      } else if (type === "conversation") {
        let conversation: Conversation[] = [];
        conversation = res.docs.map(docItem => {
          return MailUtils.mapSolrEmailToConversation(docItem);
        });
        this.setConversationIsLoading(false);
        if (conversation.length > 0) {
          this.setConversationLoadedSuccess(conversation);
          response.next(conversation);
        }
      }
    } else {
      this.setConversationIsLoading(false);
      response.next([]);
    }
    return response.asObservable();
  }

  toggleMessageFlag(conversation: Conversation, flag: string): void {
    if (!this.isOnline) {
      /* Offline star to detail view message */
      if (conversation.m && conversation.m.length > 0) {
        conversation.m.map((m: Message) => {
          const bodyItem = { id: m.id, op: flag };
          this.messageAction(bodyItem).subscribe(res => {
          });
        });
      }
    }
  }

  removeMailByFolderId(folderid: string) {
    const folderQuery = MailUtils.getQueryByFolderId(parseInt(folderid, 10));
    console.log("[removeMailByFolderId][folderQuery]: ", folderQuery);
    this.databaseService.getConversationsByFolder(folderQuery).subscribe(conversation => {
      if (!!conversation && conversation.length > 0) {
        const ids = conversation.map(c => c.id);
        console.log("[removeMailByFolderId][ids]: ", ids);
        this.databaseService.deleteConversations(ids);
      }
    });
  }

  removeMessageMailByFolderId(folderid: string) {
    const folderQuery = MailUtils.getQueryByFolderId(parseInt(folderid, 10));
    console.log("[removeMessageMailByFolderId][folderQuery]: ", folderQuery);
    this.databaseService.getMessagesByFolder(folderQuery).subscribe(messages => {
      if (!!messages && messages.length > 0) {
        const ids = messages.map(c => c.id);
        console.log("[removeMessageMailByFolderId][ids]: ", ids);
        this.databaseService.deleteMessages(ids);
      }
    });
  }

  removeMailMessage(message: any): void {
    console.log("removeMailMessage: ", message);
    const body = { id: message.id, "op": "trash" };
    if (this.isFakeId(message.id)) {
      this.databaseService.deleteMessage(message.id).subscribe(() => {
        setTimeout(() => {
          this.store.dispatch(new RemoveManyMessages([message.id]));
          this.store.dispatch(new RemoveManyConversations([message.id]));
          this.store.dispatch(new RemoveManyConversations([message.cid]));
          this.removeMessageFromConvRedux([message.id]);
          this.databaseService.deleteConversation(message.cid).subscribe();
          this.databaseService.deleteConversation(message.id).subscribe();
          this.databaseService.deleteMessage(message.id).subscribe();
          this.mailBroadcaster.broadcast("MAIL_FOLDER_UPDATE_AFTER_MOVE");
        }, 1000);
      });
    } else {
      this.mailService.messageAction(body).subscribe(res => {
        setTimeout(() => {
          this.store.dispatch(new RemoveManyMessages([message.id]));
          this.store.dispatch(new RemoveManyConversations([message.id]));
          this.store.dispatch(new RemoveManyConversations([message.cid]));
          this.removeMessageFromConvRedux([message.id]);
          this.databaseService.deleteConversation(message.cid).subscribe();
          this.databaseService.deleteConversation(message.id).subscribe();
          this.databaseService.deleteMessage(message.id).subscribe();
          this.mailBroadcaster.broadcast("MAIL_FOLDER_UPDATE_AFTER_MOVE");
        }, 1000);
      });
    }
  }

  maybeResyncStale(query: string) {
    console.log("[convRepo][maybeResyncStale] ", query);
    const folderId = (query === "in:trash") ? "3" : "6";
    const folderPath = (query === "in:trash") ? "/Trash" : "/Drafts";
    let dbFolderMessageCount = 0;
    this.databaseService.getFolders().pipe(take(1)).subscribe(fr => {
      if (fr && Array.isArray(fr)) {
        fr.forEach(f => {
          if (f.id === folderId) {
            dbFolderMessageCount = parseInt(f.n, 10);
          }
        });
      }
    });
    this.mailService.getMessageFolder(folderPath).pipe(take(1)).subscribe(data => {
      console.log("[convRepo][maybeResyncStale] got database getMessageFolder data: ", data);
      if (!!data && !!data.m) {
        if ((data.m.length !== dbFolderMessageCount)) {
          let onlineIds = [];
          console.log("[convRepo][maybeResyncStale] RESYNC required: ", dbFolderMessageCount, data);
          data.m.forEach(el => {
            onlineIds.push(el.id);
          });
          let dbMessageIds = [];
          let oQuery: SearchRequest = {
            query: query,
            originalQuery: query
          };
          this.getMessagesListFromDB(oQuery, true).subscribe(dbMessages => {
            dbMessages.forEach(dbm => {
              dbMessageIds.push(dbm.id);
              if (onlineIds.indexOf(dbm.id) === -1) {
                this.removeMailMessage(dbm);
              }
            });
            console.log("[convRepo][maybeResyncStale] RESYNC onlineIds: ", onlineIds);
            console.log("[convRepo][maybeResyncStale] RESYNC dbMessageIds: ", dbMessageIds);
          });
        }
      }
    });
  }

  getMessageCountInDatabaseByFolder(folder) {
    return this.databaseService.getMessageCountInDatabaseByFolder(folder);
  }

  resyncMessagesFolder(query: string, absFolderPath: string) {
    console.log("[convRepo][resyncMessagesFolder] ", query, absFolderPath);
    const response = new Subject<any>();

    this.mailService.getMessageFolder(absFolderPath).pipe(take(1)).subscribe(data => {
      console.log("[convRepo][resyncMessagesFolder] got online data: ", data);
      if (!!data && !!data.m) {
        this.databaseService.addNewMessagesOnly(data.m).subscribe(() => {
          response.next(true);
        });
      } else {
        response.next(true);
      }
    }, err => {
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  checkForInvalidMessages() {
    if (!this.resyncInvalidMessagesRunning) {
      let invalidIds = this.databaseService.getInvalidMsgIds();

      console.log("checkForInvalidMessages for resync found: ", invalidIds);
      if (invalidIds.length > 0) {
        this.resyncInvalidMessagesRunning = true;
        this.resyncInvalidMessages(invalidIds);
      } else {
        this.databaseService.clearInvalidMessageIds();
        this.resyncInvalidMessagesRunning = false;
        this.mailBroadcaster.broadcast("HIDE_SYNC_SPINNER");
        console.log("checkForInvalidMessages for resync finished...");
      }
    }
  }

  resyncInvalidMessages(ids: any) {
    if ((ids.length > 0) && this.isOnline) {
      let resyncId = ids[0];

      this.mailService.retrieveMessagesById(resyncId).subscribe(messages => {
        console.log(`[ConversationRepository][retrieveMessagesByIdFromServer] messages`, messages);
        // save to DB
        this.addMessagesToDB(messages).subscribe(() => {
          let remainingIds = ids.slice(1);
          if (remainingIds.length > 0) {
            setTimeout(() => {
              this.resyncInvalidMessages(remainingIds);
            }, 100);
          } else {
            this.databaseService.clearInvalidMessageIds();
            this.resyncInvalidMessagesRunning = false;
            console.log("checkForInvalidMessages for resync finished...");
            if (CommonUtils.isOnAndroid()) {
              this.mailBroadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
            } else {
              this.mailBroadcaster.broadcast("REFRESH_ONE_PAGE_SYNC");
            }
          }
        });

      }, error => {
        if (error && typeof error === "string" && error.indexOf("no such message") !== -1) {
          this.databaseService.deleteMessage(resyncId).subscribe(() => {
            let remainingIds = ids.slice(1);
            if (remainingIds.length > 0) {
              setTimeout(() => {
                this.resyncInvalidMessages(remainingIds);
              }, 100);
            } else {
              this.databaseService.clearInvalidMessageIds();
              this.resyncInvalidMessagesRunning = false;
              console.log("checkForInvalidMessages for resync finished...");
              if (CommonUtils.isOnAndroid()) {
                this.mailBroadcaster.broadcast("REFRESH_ONE_PAGE_SYNC_MOBILE");
              } else {
                this.mailBroadcaster.broadcast("REFRESH_ONE_PAGE_SYNC");
              }
            }
          });
        }
        console.error("[ConversationRepository][resyncInvalidMessages retrieveMessagesById] error ", error);
        console.error("[ConversationRepository][resyncInvalidMessages retrieveMessagesById]  ", error, navigator.onLine);
      });
    }
  }

  getFirstMessageByFolder(folderName, order): Observable<Message[]> {
    return this.databaseService.getFirstMessageByFolder(folderName, order);
  }

  addPendingOperation(objectId: string, op: string, request: any): Observable<any> {
    const response = new Subject<any>();
    if (this.databaseService.isUsingIndexedDb() && !!this.configService.worker) {
      this.configService.worker.postMessage({ type: "addPendingOp", id: new Date().getTime(), args: { id: objectId, type: op, request: request } });
      setTimeout(() => {
        response.next(true);
      }, 10);
    } else {
      return this.databaseService.addPendingOperation(objectId, op, request);
    }
    return response.asObservable().pipe(take(1));
  }

  processPendingOps(): Observable<any> {

    const response = new Subject<any>();
    console.log("ConvRepoProcessPendingOps: ", this.multiTabPendingLock$.value);
    if (this.multiTabPendingLock$.value > 0) {
      // maybe trigger unlock
      setTimeout(() => {
        response.next(true);
      }, 5);
    } else {
      if (this.databaseService.isUsingIndexedDb() && !!this.configService.worker) {
        this.multiTabPendingLock$.next(Date.now());
        this.configService.worker.postMessage({ type: "processPendingOps", id: new Date().getTime(), args: { } });
        setTimeout(() => {
          response.next(true);
        }, 5);
      } else {
        return this.processPendingOperations();
      }
    }
    return response.asObservable().pipe(take(1));
  }

  addAttachmentToDB(attachment: any): Observable<any> {
    return this.databaseService.addAttachment(attachment);
  }

  fetchAttachmentById(id: string): Observable<any> {
    return this.databaseService.fetchAttachmentById(id);
  }

  isUsingIndexedDb() {
    return this.databaseService.isUsingIndexedDb();
  }

  triggerWorkerDownloadIfRequired(messageid, part) {
    let result = false;
    if (this.databaseService.isUsingIndexedDb() && !!this.configService.worker) {
      result = true;
      this.configService.worker.postMessage({ type: "triggerWorkerAttachmentDownloadIfRequired", id: new Date().getTime(), args: { id: messageid, part: part} });
    }
    return result;
  }

  setMultiTabPendingLock(ts: number) {
    console.log("ConvRepo setMultiTabPendingLock: ", ts);
    this.multiTabPendingLock$.next(ts);
  }
  getMultiTabPendingLock() {
    return this.multiTabPendingLock$.value;
  }

  setQuickFilterString(searchString: string) {
    this.quickFilterSearchString$.next(searchString);
  }

  getQuickFilterSearchString(): Observable<any> {
    return this.quickFilterSearchString$.asObservable();
  }

  private updateMessagesFromServerById(ids: string[]): Promise<any> {
    return new Promise(resolve => {
      // console.log("updateMessagesFromServerById ids: ", ids);
      let result = {};
      if (ids.length > 0) {
        let promises = [];
        for (let i = 0; i < ids.length; i++) {
          promises.push(this.retrieveMessagesByIdFromServerObject(ids[i]));
        }
        // console.log("updateMessagesFromServerById promises: ", promises);
        forkJoin(promises).subscribe( (results: any) => {
          for (let i = 0; i < results.length; i++) {
            result = { ...result, ...results[i]};
          }
          // console.log("updateMessagesFromServerById result: ", result);
          resolve(result);
        });
      } else {
        resolve(result);
      }
    });
  }

  private retrieveMessagesByIdFromServerObject(id: string) :Observable<any> {
    const response = new Subject<any>();
    let result = {};
    this.retrieveMessagesByIdFromServer(id).subscribe(msgs => {
      // console.log("updateMessagesFromServerById retrieve ", id, msgs);
      result[id] = msgs[0];
      response.next(result);
    });
    return response.asObservable().pipe(take(1));
  }
}
