
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { HostListener, Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable, Subject, of, empty, BehaviorSubject, throwError } from "rxjs";
import { MailConstants } from "./common/utils/mail-constants";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { ElectronService } from "./services/electron.service";
import { map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ConfigService {
  private loginIFrameHandled = false;

  private lang = ["en", "de"];

  private config = {
    "appsEnabled": [{
      "title": "VNCmail",
      "route": "/mail",
      "enabled": true
    }],
    "lang": "en"
  };
  AVATAR_SYNC_INTERVAL = 5 * 60 * 1000; // 5 mins
  appInBackground = false;
  selectedServer: any;
  API_URL: string;
  prefs: any = {};
  URLS: any = {};
  hideAppsMenu = false;
  showMailDeleteConfirmDialog = false;
  briefcaseRenameFileExtensionReadOnly = false;
  useFullComposeModeOnly = false;
  backgroundSend = false;
  showZimletsOptionInSidebar = false;
  prefixBold: string;
  suffixNormal: string;

  showHideSettingsMenu: any = {};
  zimbraURL: string;
  currentLanguage = new BehaviorSubject<string>("en");
  preferences = new BehaviorSubject<any>(this.prefs);
  private config$ = new BehaviorSubject<any>(this.config);
  language = "en";
  private usedQuota = new Subject();
  private usedQuotaPercent = new BehaviorSubject<number>(0);
  hideProfile: boolean = false;
  isZimbraUIAvailable: boolean = true;
  hideAddExternalCalender: boolean = false;
  hideResourceOptionCalendar: boolean = false;
  hideAppleIcalDelegationFromPrefs: boolean = false;
  hideSendFreeBusyLinkOptionsInCalender: boolean = false;
  two_factor_authentication: boolean = false;
  private showLoginIframeKeyboardTimer;
  displayHeaderAvatar: boolean = true;
  avatarURL: any;
  totalSolrCount: number = 0;
  useVNCdirectoryAuth: boolean = false;
  deleteInvitationOnProposeNewTime: boolean = false;
  isZimbraBriefcaseEnabled: boolean = true;
  worker: Worker;
  constructor(private http: HttpClient,
              private translate: TranslateService,
              private electronService: ElectronService) {
      this.API_URL = "";
      this.setLanguage();
      this.loadConfig();
      if (localStorage.getItem("showContactActions") !== null) {
        this.set("showContactActions", localStorage.getItem("showContactActions") === "true");
      }
      if (localStorage.getItem("publicVncDirectoryUrl") !== null) {
        this.set("publicVncDirectoryUrl", localStorage.getItem("publicVncDirectoryUrl"));
      }
      if (localStorage.getItem("showContactActivity") !== null) {
        this.set("showContactActivity", localStorage.getItem("showContactActivity") === "true");
      }
      if (localStorage.getItem("displayHeaderAvatar") !== null) {
        this.set("displayHeaderAvatar", localStorage.getItem("displayHeaderAvatar") === "true");
      }
      if (localStorage.getItem("avatarURL") !== null) {
        this.set("avatarURL", localStorage.getItem("avatarURL"));
      }
      if (localStorage.getItem("canUpdateAvatar") !== null) {
        this.set("canUpdateAvatar", localStorage.getItem("canUpdateAvatar") === "true");
      }
      if (localStorage.getItem("prefixBold") !== null) {
        this.set("prefixBold", localStorage.getItem("prefixBold"));
      }
      if (localStorage.getItem("suffixNormal") !== null) {
        this.set("suffixNormal", localStorage.getItem("suffixNormal"));
      }
  }

  setPreferences(prefs: any): void {
    this.prefs = {...this.prefs, ...prefs};
    this.preferences.next(this.prefs);
    if (this.electronService.isElectron) {
      this.electronService.setToStorage("preferences", this.prefs);
    } else {
      localStorage.setItem("preferences", JSON.stringify(this.prefs));
    }
  }

  getAllConfig() {
    return this.config$.asObservable();
  }

  setCurrentLanguage(selectedLanguage: string) {
    this.language = selectedLanguage;
    this.currentLanguage.next(selectedLanguage);
  }

  setLanguage() {
    let selectedLanguage = null;
    let browserLang = this.translate.getBrowserLang();
    const localLang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.LANGUAGE_KEY)
        : localStorage.getItem(MailConstants.LANGUAGE_KEY);
    if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
      browserLang = localLang;
    }
    selectedLanguage = browserLang.match(/en|de/) ? browserLang : "en";
    this.updateLanguage(selectedLanguage);
  }

  updateLanguage(selectedLanguage: any) {
    this.language = selectedLanguage;
    this.currentLanguage.next(selectedLanguage);
    this.language = selectedLanguage;
    this.translate.use(selectedLanguage);
  }

  loadConfig(): void {

    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    if (!isCordovaOrElectron) {
      console.log("[loadConfig] !environment.isCordova !environment.isElectron");
      this.setAPIURL("");
      this.selectedServer = true;
    } else {
      const serverURL = localStorage.getItem("serverURL");
      if (serverURL) {
        localStorage.setItem("serverURL", serverURL);
        console.log("[loadConfig] ", serverURL);
        this.selectedServer = true;
        this.setAPIURL(serverURL.trim());
      }
    }
  }

  updateConfig(config: any): void {
    console.log("updateConfig", config);
    this.config = {...this.config, ...config};
    this.config$.next(this.config);
  }

  set(key: string, value: any): any {
    const oldValue = this.config[key];
    if (oldValue !== value) {
      console.debug("[config.set(" + key + ")]", oldValue, " => ", value);
    }
    this.config[key] = value;
    this.config$.next(this.config);
    return this.config;
  }

  get(key): any {
    const result = this.config[key];
    return result;
  }

  getOrThrow(key): any {
    if (!this.config.hasOwnProperty(key)) {
      throw new Error("Missing '" + key + "' in config");
    }
    return this.config[key];
  }

  setAPIURL(url: string): void {
    this.API_URL = url;
    console.log("[Set API URL]", url);
  }

  showError(msg) {
    console.log(`Invalid configuration: ${msg}`);
  }

  getSupportedLangs() {
    return this.lang.slice();
  }

  getDefaultLang() {
    return this.lang[0];
  }

  getConfiguredLang() {
    const lang = this.get("lang");
    if (this.lang.indexOf(lang) >= 0) {
      return lang;
    }
    return this.getDefaultLang();
  }

  isAppEnabled(appTitle: string) {
    const apps = this.config["appsEnabled"] || [];
    const app = apps.filter(a => a.title === appTitle)[0];
    return app && app.enabled;
  }

  setServerUrl(url: string) {
    this.config["PORTAL_URL"] = url;

    if (this.electronService.isElectron) {
      this.electronService.setToStorage("portal_url", url);
    } else {
      localStorage.setItem("portal_url", url);
    }
  }

  setDefaultServerUrl() {
    const url = this.electronService.isElectron
        ? this.electronService.getFromStorage("PORTAL_URL")
        : localStorage.getItem("PORTAL_URL");
    this.setServerUrl(url);
    return url;
  }

  getServerUrl() {
    return (
      this.electronService.isElectron
        ? this.electronService.getFromStorage("portal_url")
        : localStorage.getItem("portal_url")
    );
  }

  clearCache() {
    if (this.electronService.isElectron) {
      this.electronService.deleteFromStorage("portal_url");
    } else {
      localStorage.removeItem("portal_url");
    }
  }

  getConfig() {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(this.API_URL + "/api/config", {headers});
  }

  loginIframe(currentUrl?: string) {
    console.log("[config.ts] inside loginIframe()");
    console.log("[config.ts - currentUrl]", currentUrl);
    let loginUrl;
    if (currentUrl) {
      if (currentUrl.startsWith("/contacts")) {
        let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/contacts")[0];
        initialHref = environment.isCordova ? initialHref.split("/contacts")[0] : initialHref.split("/index.html")[0];
        loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
      } else if (currentUrl.startsWith("/briefcase")) {
        let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/briefcase")[0];
        initialHref = environment.isCordova ? initialHref.split("/briefcase")[0] : initialHref.split("/index.html")[0];
        loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
      } else if (currentUrl.startsWith("/calendar")) {
        let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/calendar")[0];
        initialHref = environment.isCordova ? initialHref.split("/calendar")[0] : initialHref.split("/index.html")[0];
        loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
      } else if (currentUrl.startsWith("/preferences")) {
        let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/preferences")[0];
        initialHref = environment.isCordova ? initialHref.split("/preferences")[0] : initialHref.split("/index.html")[0];
        loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
      } else {
        let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
        initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref.split("/index.html")[0];
        loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
      }
    } else {
      let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
      initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref.split("/index.html")[0];
      loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
      console.log("[config.service] launching login iframe with url", loginUrl);
    }
    console.log("[config.ts - loginUrl]", loginUrl);
    if (document.querySelector("#loginIframe") !== null) {
      document.querySelector("#loginIframe").remove();
    }
    if (!this.selectedServer) {
      console.log("[config.ts] return");
      return;
    }

    const iframe = document.createElement("iframe");

    if (iframe) {
      iframe.id = "loginIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";
      iframe.style.border = "none";

      // iframe.onload = () => {
      //   iframe.contentWindow.document.querySelector("html").style.height = "100%";
      //   console.log("[loginIframe] in onload");
      // };

      if (document.querySelector("vp-app") !== null && document.querySelector("#loginIframe") === null) {
        document.querySelector("vp-app").appendChild(iframe);
      } else {
        if (document.querySelector("body") !== null && document.querySelector("#loginIframe") === null) {
          document.querySelector("body").appendChild(iframe);
        }
      }
    } else {
      console.log("[config.ts] no iframe");
    }
  }

  logoutIframe() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    const iframe = document.createElement("iframe");
    if (iframe) {
        iframe.id = "logoutIframe";
        iframe.setAttribute("src", this.API_URL + "/api/call-logout");
        iframe.setAttribute("width", "100%");
        iframe.setAttribute("height", "100%");
        iframe.style.height = "100%";
        iframe.style.width = "100%";
        iframe.style.position = "fixed";
        iframe.style.zIndex = "999";
        iframe.style.border = "none";
        if (document.querySelector("body") !== null && document.querySelector("#logoutIframe") === null) {
            if (document.querySelector("#loginIframe") !== null) {
                document.querySelector("#loginIframe").remove();
            }
            document.querySelector("body").appendChild(iframe);
        }
        if (document.querySelector("vp-commander") !== null) {
            const commander = <HTMLElement>document.querySelector("vp-commander");
            commander.style.display = "none";
        }
    }
  }


  tfaOtpIframe() {
    console.log("[ConfigService][tfaOtpIframe] selectedServer: ", this.selectedServer);

    if (!this.selectedServer) {
      console.log("[ConfigService][tfaOtpIframe] return");
      return;
    }

    let iframe = document.createElement("iframe");
    if (iframe) {
      const initialHref = environment.isCordova ? window.location.href.split("/www")[0] : window.location.href.split("/mail")[0];
      const loginUrl = `${ initialHref }${ environment.isCordova ? "/www" : "" }/assets/tfa-otp.html`;
      console.log("[config.service] launching tfa otp iframe with url", loginUrl);

      iframe.id = "tfaOtpIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";

      // iframe.onload = () => {
      //   console.log("[loginIframe] in onload");
      // };

      if (document.querySelector("body") !== null && document.querySelector("#tfaOtpIframe") === null) {
        document.querySelector("body").appendChild(iframe);
      }

      // by some reason, a standard '.focus()' does not work in login.html for Android
      this.showKeyboard();

      if (document.querySelector("vnc-commander") !== null) {
        let commander = <HTMLElement>document.querySelector("vnc-commander");
        commander.style.display = "none";
      }
    } else {
      console.log("[config.ts] no iframe");
    }
  }

  hideTfaOtpIframe() {
    if (document.querySelector("#tfaOtpIframe") !== null) {
      document.querySelector("#tfaOtpIframe").remove();

      this.hideKeyboard();
    }
  }

  showKeyboard() {
    if (environment.isCordova && typeof Keyboard !== "undefined")  {
      this.showLoginIframeKeyboardTimer = setTimeout(() => {
        Keyboard.show();
        this.showLoginIframeKeyboardTimer = null;
      }, 100);
    }
  }

  hideKeyboard() {
    // by some reason, a standard '.focus()' does not work in login.html for Android,
    // so we show a keyboard in 'config.service.ts' using a Keyboard class.
    // So then we needd to hide it here once login is successful

    if  (!this.showLoginIframeKeyboardTimer) {
      clearTimeout(this.showLoginIframeKeyboardTimer);
      this.showLoginIframeKeyboardTimer = null;
    } else {
      if (environment.isCordova && typeof Keyboard !== "undefined")  {
        setTimeout(() => {
          Keyboard.hide();
        }, 100);
      }
    }
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

  private handleErrorObservable(error: Response | any) {
    return throwError(error.message || error);
  }

  private handleErrorPromise(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  setUsedQuota(userUsedQuota: number): void {
    this.usedQuota.next(userUsedQuota);
  }

  getUsedQuota() {
    return this.usedQuota.asObservable();
  }

  setUsedQuotaPercent(usedPercent) {
    this.usedQuotaPercent.next(usedPercent);
  }

  getUsedQuotaPercent() {
    return this.usedQuotaPercent.value;
  }

  @HostListener("window:resize", ["$event"])
  handleLoginResize() {
    console.log("[loginIframe] in window resize");
    // Added this because of the following reasons:
    // 1. When adding iFrame with position: fixed, scrolling doesn't work...
    // 1. When adding iFrame with position: absolute, white bar shows on startup and disappears on opening keyboard...
    if (!this.loginIFrameHandled) {
      this.loginIFrameHandled = true;
      document.getElementById("loginIframe").style.position = "absolute";
    }
  }
}
